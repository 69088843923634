import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import BreadCrumb from "../../components/BreadCrumb";
import { getInstantFeedBack } from "../../store/dashboard/Actions";
import CommonLoader from "../../components/CommonLoader";
import { getAllSchoolNameList } from "../../store/usermanage/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import history from "../../History";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAttendanceStudentView } from "../../store/profile/Actions";
import { formValidationPatten } from "../../services/Constants";
import Cookies from "universal-cookie";
import { fetchProfilePost, stopYearData, YearListGet } from "../../store/profile/Actions";
import { PropsInstantFeed } from './Types';

export interface PostsIntantFeedbackProps
  extends RouteComponentProps<OwnPropsParams> {
  getYear?:any,
  getInstantFeedBack: (postValue: any) => any;
  getAllSchoolNameList: () => any;
  getAttendanceStudentView: (postValue: any) => any;
  fetchProfilePost: () => any;
	stopYearData:() => any;
  YearListGet: (postValue?: any) => any;
  loading: boolean;
  loginProfile: any;
  getInstantFeedbackData: any;
  pageInstantFeedback: number;
  per_pageInstantFeedback: number;
  totalPageInstantFeedback: number;
  totalInstantFeedback: number;
  getSchoolList: any;
  stopYear?:any,
}

export class InstantFeedback extends Component<
  PostsIntantFeedbackProps,
  PropsInstantFeed
> {
  cookies: any = new Cookies();
  getClassPropsValue: any;
  getSchoolId: any;
  constructor(props: any) {
    super(props);
    this.state = {
      slectSchoolId: this.props.match.params.id || null,
      getCurrectYear: null,
      getCorrectSchoolId: null,
      hasMore: true,
      prev: 0,
      next: 10,
      acsOrder: true,
      descOrder: false,
      page: 1,
      per_page: 10,
      records: [],
      total: 0,
      totalPage: 0,
      search: "",
      SortOrderData: "",
      OrderNameData: "",
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order'
    };
    this.getSchoolDetails = this.getSchoolDetails.bind(this);
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getClassPropsValue = this.props.match.params.id;
    this.getInstantFeedbackData()
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getInstantFeedbackData(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getInstantFeedbackData(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
      this.setState({getCurrectYear:getYear})
      this.getInstantFeedback(getYear)
    }else {
      this.setState({getCurrectYear:this.props.getYear})
      this.getInstantFeedback(this.props.getYear)
    }
  }
  getInstantFeedback(getYear?:any){
    const { loginProfile } = this.props;
    if (loginProfile) {
      const getUserType: any = loginProfile.usertype;
      if (getUserType === 1) {
        this.getSchoolNameList(getYear);
      } else {
        this.InstantFeedbackData(getYear);
      }
    }
  }
  getSchoolNameList(getYear?:any) {
    const { slectSchoolId } = this.state;

    this.props.getAllSchoolNameList().then((res: any) => {
      if (slectSchoolId === null) {
        const { getSchoolList } = this.props;
        if (getSchoolList.length > 0) {
          this.setState({ slectSchoolId: getSchoolList[0].id });
          this.props.YearListGet({school_id:getSchoolList[0].id});
        }
        this.InstantFeedbackAcademic(getYear);
      } else {
        this.InstantFeedbackAcademic(getYear);
      }
    });
  }
  InstantFeedbackAcademic(getYear?:any) {
    const { slectSchoolId } = this.state;
    if (slectSchoolId) {
      const postValue = {
        page_no: 1,
        academic_year: getYear,
        school_id: slectSchoolId,
      };
      this.props.getInstantFeedBack(postValue).then((res:any)=>{
		window.scrollTo(0, 0);
        this.setState({hasMore:true, page:1})
      });
    }
  }
  InstantFeedbackData(academicYear?:any) {
    const postValue = {
      page_no: 1,
      academic_year: academicYear,
    };
    this.props.getInstantFeedBack(postValue).then((res:any)=>{
		window.scrollTo(0, 0);
        this.setState({hasMore:true, page:1})
      });
  }
  getInstantFeedBackResult = (getValue: any) => {
    const { slectSchoolId } = this.state;
    if (getValue) {
      const postValue: any = {
        class_id: getValue.class_id,
        school_id: slectSchoolId,
      };
      this.cookies.set("VIEWINTANTFEEDBACK", postValue);
      history.push({
        pathname: `/view_instant_feedback/${getValue.id}`,
      });
      this.cookies.set("VIEWINTANTFEEDBACK", postValue);
      this.props.getAttendanceStudentView(postValue);
    }
  };
  public fetchMoreInstantFeedback = () => {
    const { slectSchoolId, getCurrectYear } = this.state;
    if (this.state.hasMore === true) {
      if (
        Math.ceil(
          this.props.totalInstantFeedback / this.props.per_pageInstantFeedback
        ) > this.props.pageInstantFeedback
      ) {
        const postValue = {
          page_no: this.state.page + 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year: getCurrectYear,
          school_id: slectSchoolId,
        };
        this.props.getInstantFeedBack(postValue);
        this.setState({
          page: this.state.page + 1,
        });
      }

      if (
        Math.ceil(
          this.props.totalInstantFeedback / this.props.per_pageInstantFeedback
        ) === this.props.pageInstantFeedback
      ) {
        this.setState({
          hasMore: false,
        });
      }
    }
  };
  public showFilterData = (getdata: any) => {
    const { slectSchoolId, getCurrectYear } = this.state;
    if (getdata.sort_by === "desc") {
      this.setState({ acsOrder: false });
      const postValue = {
        page_no: 1,
        academic_year: getCurrectYear,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        school_id: slectSchoolId,
        search: this.state.search,
      };
      this.props.getInstantFeedBack(postValue);
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      });
    } else {
      this.setState({ acsOrder: true });
      const postValue = {
        page_no: 1,
        academic_year: getCurrectYear,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        school_id: slectSchoolId,
        search: this.state.search,
      };
      this.props.getInstantFeedBack(postValue);
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      });
    }
  };
  handleInputInstantFeedback = (e: any) => {
    const { slectSchoolId, getCurrectYear } = this.state;
    const { value } = e.target;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if (intRegex.test(getSearchValue) || getSearchValue === "") {
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          search: getSearchValue,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year: getCurrectYear,
          school_id: slectSchoolId,
        };
        this.props.getInstantFeedBack(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue,
        });
      } else {
        const postSearchValue = {
          page_no: 1,
          search: "",
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year: getCurrectYear,
          school_id: slectSchoolId,
        };
        this.props.getInstantFeedBack(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: "",
        });
      }
    }
  };
  private instantFeedBackTable(record: any) {
    const { loading } = this.props;
    const {sort_by, sort_class_name, sort_title_name} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    return (
      <InfiniteScroll
        dataLength={this.props.getInstantFeedbackData.length}
        next={this.fetchMoreInstantFeedback}
        hasMore={this.state.hasMore}
        loader={<h4 style={loadingTextCSS}>Loading...</h4>}
      >
        <table className="table table-striped custom-table table-hover">
          <thead>
            <tr>
              <th>
                  <button
                    onClick={() =>
                      this.showFilterData({ sort_by: sort_by, order_by: "name" })
                    }
                    title={sort_title_name}
                    className="headerBold"
                  >
                    Name&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i>
                  </button>
              </th>
              <th>
                  <button
                    onClick={() =>
                      this.showFilterData({
                        sort_by: sort_by,
                        order_by: "class_name",
                      })
                    }
                    title={sort_title_name}
                    className="headerBold"
                  >
                    Class Name&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i>
                  </button>
              </th>
              <th>
                  <button
                    onClick={() =>
                      this.showFilterData({
                        sort_by: sort_by,
                        order_by: "subject_name",
                      })
                    }
                    title={sort_title_name}
                    className="headerBold"
                  >
                    Subject &nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i>
                  </button>
              </th>
              <th>
                  <button
                    onClick={() =>
                      this.showFilterData({
                        sort_by: sort_by,
                        order_by: "performance",
                      })
                    }
                    title={sort_title_name}
                    className="headerBold"
                  >
                    Performance (Average)&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i>
                  </button>
              </th>
              <th>
                  <button
                    onClick={() =>
                      this.showFilterData({
                        sort_by: sort_by,
                        order_by: "created_at",
                      })
                    }
                    title={sort_title_name}
                    className="headerBold"
                  >
                    Time&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i>
                  </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {record && record.length > 0 ? (
              record.map((items: any, index: any) => (
                <tr key={index}>
                  <td className="titleCapitalize">
                    <Link
                      to={`#`}
                      onClick={() => this.getInstantFeedBackResult(items)}
                    >
                      {items.name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      onClick={() => this.getInstantFeedBackResult(items)}
                    >
                      {items.class_name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      onClick={() => this.getInstantFeedBackResult(items)}
                    >
                      {items.subject_name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      onClick={() => this.getInstantFeedBackResult(items)}
                    >
                      {items.performance}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      onClick={() => this.getInstantFeedBackResult(items)}
                    >
                      {items.created_at}
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <CommonLoader />
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    );
  }

  getSchoolDetails = (event: any) => {
    const {getCurrectYear} = this.state;
    const getValue = event.target.value;
    this.setState({ slectSchoolId: event.target.value });
    if (getValue) {
      const postValue = {
        page_no: 1,
        academic_year:getCurrectYear,
        school_id: getValue,
      };
      this.props.YearListGet({school_id:getValue});
      this.props.getInstantFeedBack(postValue);
    }
  };
  refreshInstantFeedbackTable = () => {
    const {getCurrectYear} = this.state;
    this.getInstantFeedback(getCurrectYear)
    this.setState({search: ""})
  }
  render() {
    const { loading, getInstantFeedbackData, getSchoolList } = this.props;
    const { slectSchoolId, search } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    let academicAdminUserTypes: any = false;
    const getUserType: any = this.props.loginProfile.usertype;
    if (getUserType === 1) {
      academicAdminUserTypes = true;
    }
    const getSchoolListData: any = getSchoolList;
    let SchoolListAdd: any;
    if (getSchoolList) {
      SchoolListAdd = getSchoolListData;
    }

    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["Instant Feedback"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Instant Feedback"]}
              />
              {academicAdminUserTypes ? (
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-5"></div>
                  {SchoolListAdd && SchoolListAdd.length !== 0 ? (
                    <div className="col-md-3 mb-4 pull-right">
                      <select
                        name="classlist"
                        value={slectSchoolId}
                        className="form-control"
                        onChange={this.getSchoolDetails}
                      >
                        {SchoolListAdd.map((items: any) => (
                          <option value={items.id}>
                            {" "}
                            {items.school_name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                            <h4 className="pull-right">
                                Total: {this.props.totalInstantFeedback}
                            </h4>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-topline-red">
                      <div className="mr-4 mt-2"></div>
                      <div className="card-head">
                        <header></header>
                        <div className="tools">
                          <div className="d-flex">
                            <div>
                            <input
                            placeholder="Search"
                            value={search}
                            name="search"
                            className="form-control"
                            onChange={this.handleInputInstantFeedback}
                          />
                            </div>
                            <div className="ml-2 mt-1">
                                <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshInstantFeedbackTable}></span>
                                <span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                      <div className="card-body no-padding height-9">
                        <div className="table-responsive">
                          {this.instantFeedBackTable(getInstantFeedbackData)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={loadingTextCSS}>
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}
interface OwnPropsParams {
  id: string;
}
const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
    loginProfile: state.profile.profileData,
		stopYear:state.profile.yearStatusFails,
    getInstantFeedbackData: state.dashboard.records_InstantFeedBack,
    pageInstantFeedback: state.dashboard.page_InstantFeedBack,
    per_pageInstantFeedback: state.dashboard.per_page_InstantFeedBack,
    totalInstantFeedback: state.dashboard.total_InstantFeedBack,
    totalPageInstantFeedback: state.dashboard.totalPageInstantFeedBack,
    getSchoolList: state.userManage.category,
    loading: state.dashboard.loading,
  };
};

export default connect(mapStateToProps, {
  getInstantFeedBack,
  getAllSchoolNameList,
  getAttendanceStudentView,
  fetchProfilePost,
  stopYearData,
  YearListGet
})(InstantFeedback);
