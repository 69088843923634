import React, { Component } from "react";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import {
  fetchGradePost,
  fetchMyClassListGet, fetchMySectionListGet
} from "../../store/classes/Actions";
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import {
  addQuestion,
  getTeacherSubjectList,
  getAdminSubjectList,
  reqDownloadFile, GenerateRandomQuizQuestionpost
} from "../../store/question/Actions";
import { fetchGetAllClassList } from "../../store/diary/Actions";
import "./QuestionStyle.scss";
import { stopYearData } from "../../store/profile/Actions";
import { fetchBluePrintSubjectDropdownGet, fetchBluePrintLessonDropdownGet, fetchBluePrintDifficultyLevelDropdownGet } from "../../store/QuestionBank/Actions";
import { ImportQuestionValidate } from "./ImportQuestionValidation";

export type OwnQuestSetProps = {
  loading: boolean;
  records?: any;
  getChangeYearData?: any;
  stopYearDate?: any;
  loadingQuestion: boolean;
  bluePrintSubjectDropdown?: any;
  bluePrintLessonDropdown?: any;
  bluePrintDifficultyLevelDropdown?: any;
  generateQuestions?: any;
  addQuestion: (postQuestion: any) => void;
  fetchGradePost: (postValue: any) => void;
  fetchGetAllClassList: (postValue: any) => any;
  getTeacherSubjectList: (getList: any) => any;
  getAdminSubjectList: (getList: any) => any;
  fetchMyClassListGet: () => any;
  reqDownloadFile:()=>any;
  stopYearData: () => any;
  fetchMySectionListGet: (grade: any) => any;
  fetchBluePrintSubjectDropdownGet: (data: any) => any;
  fetchBluePrintLessonDropdownGet: (data: any) => any;
  fetchBluePrintDifficultyLevelDropdownGet: ( ) => any;
  GenerateRandomQuizQuestionpost: (data:any) => any;
  schoolAdminClass: any;
  getClassList: any;
  getSubjectlist: any;
  getProfile: any;
  getCorrectClassList: any;
  getErrormessage: any;
  TeacherSubjectList: any;
  AdminSubjectList:any;
  getDownloadFile:any;
  ListGrade: any;
};
const initialValues = {
  questionSubmit: '',
  question_name: "",
  select_class: "",
  select_subject: "",
  lessons: "",
  question_count: "",
  difficulty_level: "",
  questionlist: [
    {
     answer:''
    }
  ],
};
interface StateTypes {
  SubjectList: any;
  ClassListSchoolAdmin: any;
  ClassListTeacher: any;
  classListStatus?: any;
  academicYear?: null;
  openCsvFileUpload?:any;
  grade?: any;
  hasMore?: boolean;
  subject?: any;
  lessons?: any;
  difficulty_level?: any;
  question_count?: number,
  internal_subject_id?: any;
  questionData?: any;
  questionSubmit?: boolean;
  buttonLabel?: string;
}

export class ImportQuestion extends Component<OwnQuestSetProps, StateTypes> {
  getQuestionList: any = [];
  formikQuestion: any;

  constructor(props: any) {
    super(props);
    this.state = {
      SubjectList: [],
      ClassListSchoolAdmin: [],
      ClassListTeacher: [],
      classListStatus: false,
      academicYear: null,
      openCsvFileUpload:false,
      grade: '',
      hasMore: true,
      subject: [],
      lessons: [],
      difficulty_level: [],
      question_count: 0,
      internal_subject_id: '',
      questionData:null,
      questionSubmit: false,
      buttonLabel: 'Generate'
    };
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getCurrectYear();
  }
  
  onDownloadFile = () =>{
    const FileDownload = require("js-file-download");
    this.props.reqDownloadFile().then(()=>{
      FileDownload(this.props.getDownloadFile, "question_template.csv");
    })
  }

  // This is life cycle is initial load  component Will Update
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData);
    }
  }
  // This is life cycle is initial load  component Did Update
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData();
    }
  }
  // This is function is used get correct year
  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.getAddQuestion(getYear);
      this.setState({ academicYear: getYear });
    } else {
      this.getAddQuestion(this.props.getChangeYearData);
      this.setState({ academicYear: this.props.getChangeYearData });
    }
  }

  getAddQuestion(getYear?: any) {
    const { getProfile } = this.props;
    const postValue = {
      academic_year: getYear,
    };
    this.props.fetchGradePost(postValue);
    if (getProfile) {
      if (getProfile.usertype === 2) {
        this.props.fetchMyClassListGet();
      } else if (getProfile.usertype === 3) {
        this.getCommonData(getYear);
      }
    }
    this.onShowUserBasedClass();
  }
  getCommonData(getYear?: any) {
    const { getProfile } = this.props;
    if (getProfile) {
      const postValue = {
        academic_year: getYear,
        school_id: getProfile.school_id,
      };
      this.props.fetchGetAllClassList(postValue).then((res: any) => { });
    }
  }
  getschoolAdmin(id: any) {
    const { getProfile } = this.props;
    const { academicYear } = this.state;
    if (getProfile) {
      if(getProfile.userType == 2){
        const postValue = {
          academic_year: academicYear,
          school_id: getProfile.school_id,
          grade_id: id,
        };
        this.props.getAdminSubjectList(postValue).then((res: any) => {
          const { AdminSubjectList } = this.props;
          if (
            AdminSubjectList &&
            AdminSubjectList.length > 0 &&
            AdminSubjectList !== undefined
          ) {
            this.setState({
              SubjectList: AdminSubjectList,
              classListStatus: false,
            });
            this.formikQuestion.setFieldValue("select_subject", "");
          } else {
            this.setState({ SubjectList: [], classListStatus: true });
            this.formikQuestion.setFieldError(
              "select_subject",
              "No matching records found"
            );
          }
        });
      }else{
      const postValue = {
        academic_year: academicYear,
        school_id: getProfile.school_id,
        class_id: id,
      };
      this.props.getTeacherSubjectList(postValue).then((res: any) => {
        const { TeacherSubjectList } = this.props;
        if (
          TeacherSubjectList &&
          TeacherSubjectList.length > 0 &&
          TeacherSubjectList !== undefined
        ) {
          this.setState({
            SubjectList: TeacherSubjectList,
            classListStatus: false,
          });
          this.formikQuestion.setFieldValue("select_subject", "");
        } else {
          this.setState({ SubjectList: [], classListStatus: true });
          this.formikQuestion.setFieldError(
            "select_subject",
            "No matching records found"
          );
        }
      });
    }
    }
  }
  getPostSubjectList = (e: any, getValue?: any) => {
    if (getValue) {
      this.getschoolAdmin(getValue);
    }
  };

  getGradeOption = (e: any, setFieldValue:any) => {
    const { academicYear } = this.state;
    const { value } = e.target;
    const getValue = value;
    this.setState({ grade: getValue, hasMore: true });
    const getClassName = {
      grade: getValue
    }
    if (getClassName) {
      this.props.fetchMySectionListGet(getClassName)
    }
  }

  getSubjectDropdown = (value: any) => {
    const { academicYear } = this.state;
    const getData = {
        academic_year: academicYear,
        internal_grade_id:value
      }
    this.props.fetchBluePrintSubjectDropdownGet(getData).then((res: any) => {
        const { bluePrintSubjectDropdown } = this.props;
        this.setState({ grade: value,subject: bluePrintSubjectDropdown });
      });
  }

  getLessonDropdown = (value: any) => {
      const { academicYear, grade } = this.state;
      const getData = {
          academic_year: academicYear,
          internal_grade_id:grade,
          internal_subject_id:value
        }
      this.props.fetchBluePrintLessonDropdownGet(getData).then((res: any) => {
          const { bluePrintLessonDropdown } = this.props;
          this.setState({ lessons: bluePrintLessonDropdown });
        });
        this.props.fetchBluePrintDifficultyLevelDropdownGet().then((res: any) => {
          const { bluePrintDifficultyLevelDropdown } = this.props;
          this.setState({ difficulty_level: bluePrintDifficultyLevelDropdown });
        });
  }
  onShowUserBasedClass() {
    const getProfile = this.props.getProfile;
    if (getProfile) {
      if (getProfile.usertype === 1 || getProfile.usertype === 2) {
        this.setState({ ClassListSchoolAdmin: this.props.schoolAdminClass });
        if (this.state.SubjectList.length > 0) {
          this.setState({ classListStatus: false });
        } else {
          this.setState({ classListStatus: true });
        }
      } else if (getProfile.usertype === 3) {
        this.setState({ ClassListTeacher: this.props.schoolAdminClass });
        if (this.state.SubjectList.length > 0) {
          this.setState({ classListStatus: false });
        } else {
          this.setState({ classListStatus: true });
        }
      }
    }
  }
  onOpenUploadOption = () =>{
    this.setState({openCsvFileUpload:!this.state.openCsvFileUpload})
  }
  render() {
    const { loading, loadingQuestion, getProfile } = this.props;

    const loadingQuestionFull = {
      display: loading || loadingQuestion ? "block" : "none",
    };
    const {
      SubjectList,
      ClassListSchoolAdmin,
      ClassListTeacher,
      classListStatus,
      internal_subject_id,
      question_count,
      grade,
      subject,
      lessons,
      difficulty_level,
      questionData
    } = this.state;
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["New Question Set"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Import Question Set"]}
                baseName={["Question Set"]}
                baseURL={["question"]}
              />
              {SubjectList && getProfile && this.props.getClassList ? (
                <div className="row">
                  <div className="col-md-12">                   
                    <Formik
                      ref={(node) => (this.formikQuestion = node)}
                      initialValues={initialValues}
                      validationSchema={ImportQuestionValidate}
                      onSubmit={(values, {setFieldValue,setFieldTouched, validateForm}) => {
                        console.log("onSubmit")
                        const { academicYear, grade, lessons, subject, difficulty_level,question_count } = this.state;
                        let getQuestionValue: any = [];
                        let submitValue: any = [];
                        if (values) {
                          values.questionlist.forEach(
                            (item: any, index: any) => {
                              let optionA: any;
                              let optionB: any;
                              let optionC: any;
                              let optionD: any;
                              if (item.answer === "A") {
                                optionA = true;
                                optionB = false;
                                optionC = false;
                                optionD = false;
                              } else if (item.answer === "B") {
                                optionA = false;
                                optionB = true;
                                optionC = false;
                                optionD = false;
                              } else if (item.answer === "C") {
                                optionA = false;
                                optionB = false;
                                optionC = true;
                                optionD = false;
                              } else if (item.answer === "D") {
                                optionA = false;
                                optionB = false;
                                optionC = false;
                                optionD = true;
                              }

                              submitValue = {
                                serial_no: index + 1,
                                question: item.question,
                                question_picture: "",
                                topics: item.add_topics,
                                answers: [
                                  {
                                    serial_no: "A",
                                    choice: item.question_name_A,
                                    choice_url: "",
                                    is_correct_ans: optionA,
                                  },
                                  {
                                    serial_no: "B",
                                    choice: item.question_name_B,
                                    choice_url: "",
                                    is_correct_ans: optionB,
                                  },
                                  {
                                    serial_no: "C",
                                    choice: item.question_name_C,
                                    choice_url: "",
                                    is_correct_ans: optionC,
                                  },
                                  {
                                    serial_no: "D",
                                    choice: item.question_name_D,
                                    choice_url: "",
                                    is_correct_ans: optionD,
                                  },
                                ],
                              };
                              this.getQuestionList.push(submitValue);
                            }
                          );                        
                          
                          const getSearch = {
                            internal_grade_id: grade,
                            internal_subject_id: values.select_subject,
                            wizdomwaves_lesson_id: values.lessons,
                            question_count: Number(values.question_count),
                            academic_year: academicYear,
                            difficulty_level_id: values.difficulty_level
                          };
                          if (values.questionSubmit) {
                              const createrBy: any = `${getProfile.firstname} ${getProfile.lastname}`;
                                getQuestionValue = {
                                  school_id: getProfile.school_id,
                                  academic_year: academicYear,
                                  grade_id: String(values.select_class),
                                  subject_id: String(values.select_subject),
                                  question_name: values.question_name,
                                  posted_by: createrBy,
                                  questions: this.getQuestionList,
                                };
                                this.props.addQuestion(getQuestionValue);                                
                            }                    
                           else {
                            this.props.GenerateRandomQuizQuestionpost(getSearch).then((res:any)=>{
                              const { generateQuestions } = this.props;         
                              this.setState({questionData: generateQuestions.data})           
                              const randomQuestions = generateQuestions.data.question.map((item:any) => {
                                const correctAnswer = item.result.find((option:any) => option.is_correct_answer).option;                                
                                  return {
                                      question: item.question,
                                      add_topics: item.lesson_name,
                                      question_name_A: item.result[0].content,
                                      question_name_B: item.result[1].content,
                                      question_name_C: item.result[2].content,
                                      question_name_D: item.result[3].content,
                                      answer: correctAnswer,
                                  };
                            });
                              setFieldValue("questionlist", randomQuestions); 
                              setFieldValue("questionSubmit", 'true');   
                              this.setState({buttonLabel: 'Submit'})                                                
                            });
                        }
                      }}}
                    >
                      {({
                        values,
                        errors,
                        isSubmitting,
                        isValidating,
                        dirty,
                        touched,
                        handleReset,
                        handleSubmit,
                        setFieldValue,
                        setSubmitting, 
                        setFieldTouched,
                        validateForm
                      }) => {
                        // this is the add question set validation message
                        let getAnswerList: any;
                        values.questionlist.length > 0
                          ? (getAnswerList = true)
                          : (getAnswerList = false);
                        const isQuestionEmpty =
                          !values.question_name ||
                          !values.select_class ||
                          !values.select_subject;
                        let gradeList: any;
                          if (this.props.ListGrade) {
                            gradeList = this.props.ListGrade;
                          }
                        const { generateQuestions} = this.props;
                        
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-4">
                                <FormGroup>
                                  <Field
                                    label={<><span>Question Set Name</span><span className='formmandatorycolor'> *</span></>}
                                    type="text"
                                    name="question_name"
                                    component={TextField}
                                    className="textfield__input"
                                    disabled={false}
                                    onChange={(e:any,value:any)=>{
                                      setFieldValue("question_name", e.target.value)
                                      setFieldValue("questionlist", [])
                                      setFieldValue("questionSubmit", '')
                                    }} 
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  <Field
                                  label={<><span>Grade</span><span className="formmandatorycolor"> *</span></>}
                                  name="select_class"
                                  select 
                                  component={TextField}
                                  className="textfield__input"
                                  disabled={false}
                                  onChange={(e:any,value:any)=>{
                                    this.getSubjectDropdown(e.target.value);
                                    setFieldValue("select_class", e.target.value)
                                    setFieldValue("select_subject", '')
                                    setFieldValue("lessons", '')
                                    setFieldValue("question_count", '')
                                    setFieldValue("difficulty_level", '')
                                    setFieldValue("questionlist", [])
                                    setFieldValue("questionSubmit", '')
                                  }}
                                  >
                                    {gradeList.map((item: any) => (
                                          <MenuItem value={item.grade_id}>{item.value}</MenuItem>
                                        ))
                                    }
                                  </Field>
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  <Field
                                  label={<><span>Subject</span><span className="formmandatorycolor"> *</span></>}
                                  name="select_subject"
                                  select 
                                  component={TextField}
                                  className="textfield__input"
                                  disabled={false}
                                  onChange={(e:any,value:any)=>{
                                    this.getLessonDropdown(e.target.value);
                                    setFieldValue("select_subject", e.target.value)
                                    setFieldValue("lessons", '')
                                    setFieldValue("difficulty_level", '')
                                    setFieldValue("question_count", '')
                                    setFieldValue("questionlist", [])
                                    setFieldValue("questionSubmit", '')
                                  }}
                                  >
                                    {subject && subject.length > 0 && subject.map((item: any) => (
                                        <MenuItem value={item.internal_subject_id}>
                                            {item.internal_subject_name}
                                        </MenuItem>
                                    ))}
                                  </Field>
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  <Field
                                  label={<><span>Lesson</span><span className="formmandatorycolor"> *</span></>}
                                  name="lessons"
                                  select 
                                  component={TextField}
                                  className="textfield__input w-100"
                                  disabled={false}   
                                  onChange={(e:any,value:any)=>{
                                    setFieldValue("lessons", e.target.value)
                                    setFieldValue("questionlist", [])
                                    setFieldValue("questionSubmit", '')
                                  }}                          
                                  >
                                    {lessons && lessons.length > 0 && lessons.map((item: any) => (
                                      <MenuItem value={item.uid} >
                                          {item.name}
                                      </MenuItem>
                                    ))}                      
                                  </Field>
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  <Field
                                  label={<><span>Question Count</span><span className="formmandatorycolor"> *</span></>}
                                  name="question_count"
                                  component={TextField}
                                  className="textfield_input"
                                  disabled={false}
                                  onChange={(e:any,value:any)=>{
                                    setFieldValue("question_count", e.target.value)
                                    setFieldValue("questionlist", [])
                                    setFieldValue("questionSubmit", '')
                                  }}  
                                  />
                                </FormGroup>
                              </div>
                              <div className="col-md-4">
                                <FormGroup className="w-100 mb-4">
                                  <Field
                                  label={<><span>Difficulty Level</span><span className="formmandatorycolor"> *</span></>}
                                  name="difficulty_level"
                                  select 
                                  component={TextField}
                                  className="textfield__input"
                                  disabled={false}
                                  onChange={(e:any,value:any)=>{
                                    setFieldValue("difficulty_level", e.target.value)
                                    setFieldValue("questionlist", [])
                                    setFieldValue("questionSubmit", '')
                                  }}  
                                  >
                                    {difficulty_level && difficulty_level.length > 0 && difficulty_level.map((item: any) => (
                                      <MenuItem value={item.uid} >
                                          {item.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormGroup>
                              </div>
                            </div>
                            <FieldArray
                              name="questionlist"
                              render={({ insert, remove, push }) => (
                                <>
                                  {values.questionSubmit && values.questionlist && values.questionlist.length > 0 ? (
                                    values.questionlist.map(
                                      (data: any, index: any) => (
                                        <div className="row" key={index}>
                                          <div className="col-md-12">
                                            <div className="card-box mt-4">
                                              <div className="card-body  pb-5">
                                                <div className="col-md-12 text-right ">
                                                  <button
                                                    className=""
                                                    type="button"
                                                    title="Delete Question"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <span className="deleteIcon">
                                                      <i
                                                        className="fa fa-trash"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="d-flex mt-1 w-100">
                                                  <div className="mr-3 mt-4">
                                                    {index + 1}.
                                                  </div>
                                                  <FormGroup className="w-100">
                                                    <Field
                                                      label={<><span>Write Question</span><span className='formmandatorycolor'> *</span></>}
                                                      type="text"
                                                      name={`questionlist.${index}.question`}
                                                      component={TextField}
                                                      className="textfield__input"
                                                      disabled={false}
                                                      value={data.question}
                                                    />
                                                  </FormGroup>
                                                </div>
                                                <div className="col-md-12 pr-0 mt-3">
                                                  <FormGroup className="w-100">
                                                    <Field
                                                      label={<><span>Add Topics</span><span className='formmandatorycolor'> *</span></>}
                                                      type="text"
                                                      name={`questionlist.${index}.add_topics`}
                                                      component={TextField}
                                                      className="textfield__input w-100"
                                                      disabled={false}
                                                      value={data.add_topics}
                                                    />
                                                  </FormGroup>
                                                </div>

                                                <div className="row">
                                                  <>
                                                  <ErrorMessage name={`questionlist.${index}.answer`} component="div" className="errorcolor" />
                                                  {errors.questionlist && errors.questionlist[index] && errors.questionlist[index]?.answer && (
                                                    <div className="col-md-12 text-center mt-3">
                                                      <div className="errorcolor">
                                                        {errors.questionlist[index]?.answer}
                                                      </div>
                                                    </div>
                                                  )}                                                    
                                                  </>
                                                  <div className="col-md-6">
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            color="success"
                                                            value="A"
                                                            className="bob-radio"
                                                            checked={data.answer === "A"}
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          A.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option A</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_A`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                            value={data.question_name_A}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            value="B"
                                                            checked={data.answer === "B"}
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          B.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option B</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_B`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                            value={data.question_name_B}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            value="C"
                                                            checked={data.answer === "C"}
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          C.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option C</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_C`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                            value={data.question_name_C}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                      <div className="d-flex mt-3">
                                                        <div className="mt-4 mr-3 ml-2">
                                                          <Field
                                                            type="radio"
                                                            name={`questionlist.${index}.answer`}
                                                            value="D"
                                                            checked={data.answer === "D"}
                                                          />
                                                        </div>
                                                        <div className="mr-3 mt-4">
                                                          D.
                                                        </div>
                                                        <FormGroup className="w-100">
                                                          <Field
                                                            label={<><span>Write Option D</span><span className='formmandatorycolor'> *</span></>}
                                                            type="text"
                                                            name={`questionlist.${index}.question_name_D`}
                                                            component={
                                                              TextField
                                                            }
                                                            className="textfield__input"
                                                            disabled={false}
                                                            value={data.question_name_D}
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    ))
                                  ): []}
                                </>
                              )}
                            /> 
                            <div className="text-right mb-3 mr-2 mt-4">
                              <Button className="btn btn-pink mr-1 ml-1" type="submit">{this.state.buttonLabel}</Button>                                                                                              
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              ) : (
                <SpinnerLoader />
              )}
            </div>
          </div>
        </div>
        <div style={loadingQuestionFull}>
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.classes.loading,
    generateQuestions: state.questionset.generateQuestions,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    loadingQuestion: state.questionset.loading,
    getClassList: state.classes.gradelist,
    getSubjectlist: state.subjects.category,
    getProfile: state.profile.profileData,
    schoolAdminClass: state.classes.my_class,
    getCorrectClassList: state.diary.gradelist,
    TeacherSubjectList: state.questionset.getTeacherSubjectList,
    AdminSubjectList: state.questionset.getAdminSubjectList,
    getErrormessage: state.questionset.errors,
    getDownloadFile:state.questionset.downloadFiles,
    ListGrade: state.classes.my_class,
    bluePrintSubjectDropdown: state.QuestionBank.bluePrintSubjectDropdown,
    bluePrintLessonDropdown: state.QuestionBank.bluePrintLessonDropdown,
    bluePrintDifficultyLevelDropdown: state.QuestionBank.bluePrintDifficultyLevelDropdown,
  };
};

export default connect(mapStateToProps, {
  fetchGradePost,
  addQuestion,
  fetchGetAllClassList,
  getTeacherSubjectList,
  getAdminSubjectList,
  fetchMyClassListGet,
  reqDownloadFile,
  stopYearData,
  fetchMySectionListGet,
  fetchBluePrintSubjectDropdownGet, fetchBluePrintLessonDropdownGet, fetchBluePrintDifficultyLevelDropdownGet, GenerateRandomQuizQuestionpost
})(ImportQuestion);
