import React, { Component } from 'react'
import { connect } from 'react-redux'
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { Link } from 'react-router-dom';
import CommonLoader from '../../components/CommonLoader';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import BreadCrumb from '../../components/BreadCrumb';
import styles from '../attendance/style.module.css';
import { fetchGetAllClassList } from '../../store/diary/Actions';
import {getTeacherAttendanceReport} from '../../store/teacher/Actions';
import history from '../../History';
import { stopYearData } from '../../store/profile/Actions'

interface propsTypes {
    getYear?:any;
    fetchGetAllClassList: (postPage: any) => any;
    getTeacherAttendanceReport:(postValue:any) => any;
	stopYearData:() => any;
    getAllClassList?:any;
    loader?:any;
    RecordsTeacherAttendance?:any;
    PerPageRecordsTeacherAttendance?:any;
    PageRecordsTeacherAttendance?:any;
    TotalRecordsTeacherAttendance?:any;
    stopYear?:any;

}
interface stateAttendanceType {
    getClassName:any,
    page:any,
    search:any,
    startDate: any,
    endDate: any,
    key:'selection',
    attendanceHear:any,
    attendanceDetails:any,
    academicYear?:any
}
      
export class TeacherAttendance extends Component<propsTypes, stateAttendanceType> {
    constructor(props: any) {
        super(props);
        this.state = {
            getClassName:'',
            page:1,
            search:'',
            startDate: new Date(),
            endDate: new Date(),
            key:'selection',
            attendanceHear:[],
            attendanceDetails:[],
            academicYear:null
        }
    }
    componentDidMount(): void {
      window.scrollTo(0, 0);
      this.getUpdateYear();   
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear } = this.props;
    
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getUpdateYear(getYearData?:any){
    if(getYearData && getYearData !== undefined && getYearData !== null){
      this.setState({academicYear:getYearData})
      this.commonClassDetails(getYearData)
    }else {
      this.setState({academicYear:this.props.getYear})
      this.commonClassDetails(this.props.getYear)
    }
  }
    commonClassDetails(getYearData?:any){
        let loadMoreType:any = {
              academic_year:getYearData
            }
          this.props.fetchGetAllClassList(loadMoreType).then((res:any)=>{
              const { getAllClassList } = this.props;
              if(getAllClassList && getAllClassList !== null && getAllClassList.length !== 0){
                getAllClassList.splice(0, 0,{"id":"All Teachers","grade_standard":"All Teachers","grade":"All Teachers"});
                let postValue:any = {
                    class_id:getAllClassList[0].id,
                    update_year: getYearData
                }
                  this.getTeacherAttendenceList(postValue);
                  this.setState({getClassName:getAllClassList[0].id})
              }
          });
      }
    getTeacherAttendenceList(getClassId:any){
        if(getClassId){
            let loadMoreType:any = {
                academic_year: getClassId.update_year,
                page_no:1,
                class_id:getClassId.class_id,
                from: moment(this.state.startDate).format('YYYY-MM-DD'),
                to: moment(this.state.endDate).format('YYYY-MM-DD'),
              }
              this.props.getTeacherAttendanceReport(loadMoreType).then((res:any)=>{
                const { RecordsTeacherAttendance } = this.props;
               if(RecordsTeacherAttendance && RecordsTeacherAttendance !== undefined && RecordsTeacherAttendance !== null){
                 this.setState({attendanceHear:RecordsTeacherAttendance.absent_data, attendanceDetails:RecordsTeacherAttendance.teacher_detail})
               }else {
                this.setState({attendanceHear:[], attendanceDetails:[]})
               }
              })
        }
     
    }
    handleDateSelect = (ranges:any) =>{
      let rangeStartDate : any = String(moment(String((ranges as {selection: any}).selection.startDate as Date)).format("YYYY-MM-D"))
      let rangeEndDate : any = String(moment(String((ranges as {selection: any}).selection.endDate as Date)).format("YYYY-MM-D"))
      const { getClassName, academicYear } = this.state;
      if(rangeStartDate && rangeEndDate){
          let postValue:any = {
              page_no:1,
              academic_year:academicYear,
              class_id: getClassName,
               from: rangeStartDate,
               to: rangeEndDate,
          }
          this.props.getTeacherAttendanceReport(postValue).then((res:any)=>{
            const { RecordsTeacherAttendance } = this.props;
           if(RecordsTeacherAttendance && RecordsTeacherAttendance !== undefined && RecordsTeacherAttendance !== null){
             this.setState({attendanceHear:RecordsTeacherAttendance.absent_data, attendanceDetails:RecordsTeacherAttendance.teacher_detail})
           }else {
            this.setState({attendanceHear:[], attendanceDetails:[]})
           }
          })
          this.setState({
              ...this.state,
              startDate:(ranges as {selection: any}).selection.startDate as Date,
          endDate: (ranges as {selection: any}).selection.endDate as Date
          })
      }
     
  }
    getTeacherDetails = (getValue?:any) =>{
      history.push({
        pathname: `/teachers/${getValue.teacher_id}`,
        });
        localStorage.setItem('TeacherDetails', getValue.teacher_name)
        
    }
  
    
      getfilterClasses = (e:any) => {
          const {value} = e.target;
          const {academicYear} = this.state;
          if(value){
            let loadMoreType:any = {
                academic_year:academicYear,
                page_no:1,
                class_id:value,
                from: moment(this.state.startDate).format('YYYY-MM-DD'),
                to: moment(this.state.endDate).format('YYYY-MM-DD'),
              }
              this.setState({getClassName:value})
              this.props.getTeacherAttendanceReport(loadMoreType).then((res:any)=>{
                const { RecordsTeacherAttendance } = this.props;
               if(RecordsTeacherAttendance && RecordsTeacherAttendance !== undefined && RecordsTeacherAttendance !== null){
                 this.setState({attendanceHear:RecordsTeacherAttendance.absent_data, attendanceDetails:RecordsTeacherAttendance.teacher_detail})
               }else {
                this.setState({attendanceHear:[], attendanceDetails:[]})
               }
              })
          }
        
      }
      refreshTeacherAttendanceTable = () => {
        const {academicYear} = this.state;
        this.getUpdateYear(academicYear);
      }
    render() {
        const {loader, getAllClassList} = this.props;
        const { attendanceHear, attendanceDetails } =  this.state;
        const loadingDiary = { display: loader ? "block" : "none" };
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
          }
        return (
            <div>
                 <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Teacher Attendance']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Teacher Attendance']} />
                                 <div>
                                 <div className="row">
                                    <div className="col-md-12 mb-3">
                                    <div className="mr-4 mt-2">
                                        <h4 className="pull-right">Total: {this.props.TotalRecordsTeacherAttendance || 0}</h4>
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-topline-red">
                                            <div className="card-head" >
                                                <header>
                                                    <div>
                                                        {getAllClassList && getAllClassList.length !== 0 ?                                     
                                                            <select name="classlist" className="form-control" onChange={this.getfilterClasses}>
                                                                {getAllClassList.map((items: any) => (
                                                                    <option value={items.id}> {items.grade_standard} </option>
                                                                ))}
                                                            </select>
                                                            :null}
                                                    </div>
                                                </header>
                                                <div className="tools">
                                                <div className="d-flex">
                                                <div className={styles.datePicker}>
                                                        <div style={{display:"flex", justifyContent:"space-evenly"}}> 
                                                            <p className={styles.date}>
                                                                {moment(String(selectionRange.startDate)).format("D-MMM-YYYY")}
                                                            </p>
                                                            <p> - </p>
                                                            <p className={styles.date}>
                                                                {moment(String(selectionRange.endDate)).format("D-MMM-YYYY")}
                                                            </p>
                                                        </div>
                                                        <div className={styles.active}>
                                                            <DateRangePicker
                                                                ranges={[selectionRange]}
                                                                onChange={this.handleDateSelect}
                                                                color="white"
                                                                maxDate= {new Date()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ml-2 mt-1">
                                                    <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshTeacherAttendanceTable}></span>
                                                    <span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
                                                </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="card-body no-padding height-9">
                                                {attendanceHear && attendanceDetails && attendanceDetails !== undefined && attendanceHear !== undefined?
                                                
                                                <div className="table-responsive">
                                                    <div className="">
                                                        <div>
                                                        <div >
                                                        <div  className="attendance-flex-container">
                                                        <table className="table table-striped custom-table table-hover width1Table text-left">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-left height60">Name</th>
                                                            <th className="text-left height60">Overall</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                { attendanceDetails && attendanceDetails.length !==0 ?
                                                                attendanceDetails.map((items:any, i:any)=>(
                                                                <tr key={i}>
                                                                    <td><Link to={'#'} onClick={()=> this.getTeacherDetails(items)}>{items.teacher_name}</Link></td>
                                                                    <td>{items.percentage} %</td>
                                                                    
                                                                </tr>
                                                                ))
                                                                : <tr > <th colSpan={2}><CommonLoader/></th></tr>
                                                                }
                                                                
                                                            </tbody>
                                                            </table>
                                                            <div className="attendance-container">
                                                                <div className="attendance-flex-scroll">
                                                                <table className="table table-striped custom-table table-hover text-center">
                                                        <thead>
                                                            <tr>
                                                            
                                                            {attendanceHear &&attendanceHear.length !==0 ?
                                                            attendanceHear.map((monthList:any)=>{
                                                                return(
                                                                <th className="minwidth text-center paddingcommon">
                                                            {monthList.day}
                                                            <span> {moment().month(monthList.month - 1).format("MMM")}</span>
                                                            <div className="absentbg">{monthList.absent_count} Absent</div>
                                                                </th>
                                                            )}
                                                            ) :null}

                                                            {/* <th>Attendance Time</th> */}
                                                            
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                { attendanceDetails  && attendanceDetails.length !==0?
                                                                attendanceDetails.map((items:any, i:any)=>{
                                                                    return(
                                                                        <tr>
                                                                            {items['attendance_details'] ?
                                                                            items['attendance_details'].map((attendItems:any)=>{
                                                                                return(
                                                                                    <><td style={{ color: attendItems.color_code }}>{ attendItems.attendance_value }  { attendItems.time }</td>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        :<CommonLoader/>}
                                                                        </tr>
                                                                        )
                                                                })
                                                                :null
                                                                }
                                                            </tbody>
                                                        </table>
                                                            </div>
                                                            </div>
                                                            </div>

                                                    </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                                :<CommonLoader/>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingDiary}><SpinnerLoader /></div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return{
    getYear:state.profile.getYear,
    stopYear:state.profile.yearStatusFails,
    loader:state.teacher.loading,
    getAllClassList:state.diary.gradelist,
    RecordsTeacherAttendance:state.teacher.RecordsTeacherAttendance,
    PerPageRecordsTeacherAttendance:state.teacher.PerPageTeacherAttendance,
    PageRecordsTeacherAttendance:state.teacher.PageTeacherAttendance,
    TotalRecordsTeacherAttendance:state.teacher.TotalTeacherAttendance
    }
}

export default connect(mapStateToProps, {fetchGetAllClassList, getTeacherAttendanceReport, stopYearData})(TeacherAttendance)
