import React from 'react';
import { connect } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import { RootState } from '../../store/Index';
import { RouteComponentProps } from 'react-router';
import { Button, FormGroup, MenuItem } from '@material-ui/core';
import { editSchool, fetchSchool, commonGet } from '../../store/school/Actions';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { TextField } from 'formik-material-ui';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { GoogleMap, LoadScript, Marker,useJsApiLoader } from '@react-google-maps/api';
import { MONTHLIST, 
    UserRoles, commonKeys } from '../../services/Constants';
import { schoolEdit } from './SchoolValidation';
import moment from 'moment';
import { containerStyle, stateTypes } from './Types';
import {SCHOOLMANAGE} from '../../services/Config';

function withMyHook(Component:any) {
    return function WrappedComponent(props:any) {
         const jsApiLoader = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: 'AIzaSyA3yhFDKARfSmU95yaMMQ3SbsW1kOX_gsA',
          });
          return <Component {...props} jsApiLoader={jsApiLoader} />;
    }
  }
export interface PostsListProps extends RouteComponentProps<OwnPropsParams> {    
    fetchSchool: (id: number) => any;
    editSchool: (school: any) => any;
    commonGet: (URL:any, body:any) => any;
    loading: boolean
    schoolData: any;
    schoolEditDetails: any;
    errorMessage: any;
    commonCategoryList:any;
    jsApiLoader:any;
}

class EditSchool extends React.Component<PostsListProps, stateTypes> {
    formik:any;
    jsApiLoader:any;
    onLoad:any;
    onUnmount:any;
    constructor(props:any){
        super(props)
        this.state = {
            show: false,
            showDelete: false,
            addShow: false,
            acadamicAdmin: false,
            schoolAdmin: false,
            bothData: false,
            teacher: false,
            parent: false,
            deleteSchoolId: -1,
            latitudeedit:'',
            longitudeedit:'',
            getUrlService: '',
            mapStatus:true,
            selectData: '',
            position: '',
            getStatTime: '',
            getEndTime: '',
            contactPersonsName: '',
            contactPersonsDesignation: '',
            contactPersonsPhoneNumber: '',
            contactPersonsemailId: '',
            latitude: '',
            longitude: '',
            schoolCoordinates: '',
            contactPersons: '',
            school_name: '',
            school_id: '',
            school_address: '',
            school_category: '',
            acadamic_start_month: '',
            acadamic_end_month: '',
            school_coverage_area: 0,
            isLoaded: props.jsApiLoader.isLoaded,
            map: null,
        }
        this.onLoad = this.onLoadMethod.bind(this);
        this.onUnmount = this.onUnmountMethod.bind(this);
    }

    componentDidMount(): void {     
        window.scrollTo(0, 0);
        this.getCategoryList();
        this.checkUserType();
        this.props.fetchSchool(Number(this.props.match.params.id)).then((rest:any)=>{
            const { schoolEditDetails } = this.props; 
            let schoolCoordinates:any;
            let contactPersons:any
            if(schoolEditDetails && schoolEditDetails !== null && schoolEditDetails !== undefined){
                        schoolCoordinates = schoolEditDetails.school_coordinates;
                        contactPersons = schoolEditDetails.contact_persons;
                        this.setState({ school_name: schoolEditDetails.school_name,
                        school_id: schoolEditDetails.id,
                        school_address: schoolEditDetails.address,
                        school_category: schoolEditDetails.category,
                        acadamic_start_month: schoolEditDetails.acadamic_start_month,
                        acadamic_end_month: schoolEditDetails.acadamic_end_month,
                        getStatTime: schoolEditDetails.start_time,
                        getEndTime: schoolEditDetails.end_time,
                        school_coverage_area: schoolEditDetails.coverage_area})
            
                if (schoolCoordinates) {
                    let postValue:any = {
                        lat: parseFloat(schoolCoordinates[0].latitude),
                        lng: parseFloat(schoolCoordinates[0].longitude)
                    }
                    this.setState({latitudeedit:schoolCoordinates[0].latitude, longitudeedit:schoolCoordinates[0].longitude, position:postValue})
                }
                if (contactPersons) {
                    this.setState({contactPersonsName: contactPersons[0].name,
                    contactPersonsDesignation: contactPersons[0].designation,
                    contactPersonsPhoneNumber: contactPersons[0].phone_number,
                    contactPersonsemailId: contactPersons[0].email_id})
                }               
            }
        });
    }
    onLoadMethod(map:any) {
        map.setZoom(14)
        this.setState({ map });
      }
    
      onUnmountMethod() {
        this.setState({ map: null });
      }

    getCategoryList(){
        this.props.commonGet(SCHOOLMANAGE.commonListItem, commonKeys.schoolCategory)
    }
 
    checkUserType() {
        const getToken = localStorage.getItem('usertype');
        if (getToken === UserRoles.acadamicAdmin) {
            this.setState({ acadamicAdmin: true })
            this.setState({ getUrlService: 'school' })
        } else if (getToken === UserRoles.schoolAdmin) {
            this.setState({ schoolAdmin: true })
            this.setState({ getUrlService: 'view_school' })
        } else if (getToken === UserRoles.teacher) {
            this.setState({ teacher: true })
        } else if (getToken === UserRoles.parent) {
            this.setState({ parent: true })
        }
    }

    public onMarkerDragEnd = ({ e }: { e: any }) => {
        const getselectData:any = this.props.schoolEditDetails;
        let schoolCoordinates = getselectData.school_coordinates;
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        schoolCoordinates[0].latitude=lat;
        schoolCoordinates[0].longitude=lng;
        this.setState({
            latitudeedit:schoolCoordinates[0].latitude,
            longitudeedit:schoolCoordinates[0].longitude
        })
        if(lat){
            this.setState({mapStatus:false})
        }
    };
    componentDidUpdate() {
        let getFormikValue:any = this.formik;
        if(getFormikValue) {
         if(this.props.errorMessage && this.props.errorMessage !== null && this.props.errorMessage !== undefined){
            if(this.props.errorMessage.category){
            getFormikValue.setFieldError("school_name", this.props.errorMessage.school_name);
         } else if(this.props.errorMessage.category){
             getFormikValue.setFieldError("category", this.props.errorMessage.category); 
         } else if(this.props.errorMessage.name){  
             getFormikValue.setFieldError("nameSchool", this.props.errorMessage.name);                
         }else if(this.props.errorMessage.email_id){ 
             getFormikValue.setFieldError("email_id", this.props.errorMessage.email_id)
         }else if(this.props.errorMessage.designation){
             getFormikValue.setFieldError("designationSchool", this.props.errorMessage.designation);  
         }else if(this.props.errorMessage.address){
             getFormikValue.setFieldError("addressSchool", this.props.errorMessage.address); 
         }else if(this.props.errorMessage.phone_number){
             getFormikValue.setFieldError("phoneNumber", this.props.errorMessage.phone_number); 
         }else if(this.props.errorMessage.end_time){  
             getFormikValue.setFieldError("endTime", this.props.errorMessage.end_time);
         }else if(this.props.errorMessage.start_time){
             getFormikValue.setFieldError("startTime", this.props.errorMessage.start_time);
         }else if(this.props.errorMessage.school_coverage_area){
             getFormikValue.setFieldError("schoolCoverageArea", this.props.errorMessage.school_coverage_area);
         }else if(this.props.errorMessage.acadamic_start_month){
             getFormikValue.setFieldError("acadamicStartMonth", this.props.errorMessage.acadamic_start_month); 
         }else if(this.props.errorMessage.acadamic_end_month){
             getFormikValue.setFieldError("acadamicEndMonth", this.props.errorMessage.acadamic_end_month); 
         }   
        }
    }
    if(this.state.isLoaded != this.props.jsApiLoader.isLoaded){
        this.setState({ isLoaded: this.props.jsApiLoader.isLoaded });
    }
    }
    render() {
        const { loading, commonCategoryList } = this.props;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        const { getUrlService, latitudeedit, longitudeedit, mapStatus, position,
        getStatTime, getEndTime, contactPersonsName, contactPersonsDesignation,
        contactPersonsPhoneNumber, contactPersonsemailId, latitude, longitude, 
        school_name, school_id, school_address, school_category, school_coverage_area, acadamic_start_month, acadamic_end_month} = this.state;
        const getToken = localStorage.getItem('token');
        if (!getToken) {
            history.push("/");
        }
        
        const initialSchoolEdit: any = {
            id: school_id,
            school_name: school_name,
            addressSchool: school_address,
            nameSchool: contactPersonsName,
            designationSchool: contactPersonsDesignation,
            phoneNumber: contactPersonsPhoneNumber,
            email_id: contactPersonsemailId,
            category: school_category,
            latitude: latitude,
            longitude: longitude,
            acadamicStartMonth: acadamic_start_month,
            acadamicEndMonth: acadamic_end_month,
            school_coverage_area: school_coverage_area,
            startTime: moment(getStatTime, ["h:mm A"]).format("HH:mm"),
            endTime: moment(getEndTime, ["h:mm A"]).format("HH:mm")
        }
        console.log(initialSchoolEdit)
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['School']}
                                homeName={['Home']}
                                url={['dashboard']}
                                baseName={['School']}
                                baseURL={[getUrlService]}
                                mainPageTitle={['Edit School']} />
                            { position && contactPersonsName && commonCategoryList ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-box">
                                            <div className="card-head">
                                                <header>Edit School</header>
                                            </div>
                                            <div className="card-body">
                                                <Formik
                                                ref={node=>this.formik = node}
                                                    validationSchema={schoolEdit}
                                                    initialValues={initialSchoolEdit} onSubmit={(values, actions) => {
                                                        const startTime = values.startTime;
                                                        const endTime = values.endTime;
                                                        const initialValuesList: any = {
                                                            id: values.id,
                                                            school_name: values.school_name,
                                                            address: values.addressSchool,
                                                            contact_persons: [{
                                                                name: values.nameSchool,
                                                                designation: values.designationSchool,
                                                                phone_number: values.phoneNumber,
                                                                email_id: values.email_id.toLowerCase()
                                                            }],
                                                            category: values.category,
                                                            school_coordinates: [{
                                                                latitude:latitudeedit,
                                                                longitude:longitudeedit
                                                            }],
                                                            acadamic_start_month: values.acadamicStartMonth,
                                                            acadamic_end_month: values.acadamicEndMonth,
                                                            start_time: moment(startTime, 'hh:mm').format('hh:mm A'),
                                                            end_time: moment(endTime, 'hh:mm').format('hh:mm A'),
                                                            coverage_area: values.school_coverage_area,
                                                            is_active: 'true'
                                                        }
                                                        console.log(values)
                                                        this.props.editSchool(initialValuesList)
                                                    }}>
                                                    {({ values, errors, isSubmitting, isValidating, dirty, touched, setFieldValue }) => {
                                                        let checkStatus:any;
                                                        if(!dirty === true && mapStatus === true){
                                                            checkStatus = true
                                                        }else if(!dirty === false && mapStatus === false){
                                                            checkStatus = false
                                                        }else if(!dirty === false && mapStatus === true){
                                                            checkStatus = false
                                                        }else if(!dirty === true && mapStatus === false){
                                                            checkStatus = false
                                                        }
                                                        const isSchoolEditEmpty = (!values.school_name 
                                                            || !values.category 
                                                            || !values.nameSchool 
                                                            || !values.phoneNumber 
                                                            || !values.email_id 
                                                            || !values.designationSchool
                                                            || !values.addressSchool
                                                            || !values.startTime
                                                            || !values.endTime
                                                            || !values.school_coverage_area
                                                            || !values.acadamicStartMonth
                                                            || !values.acadamicEndMonth);
                                                        return(
                                                        <Form>
                                                            <div>
                                                                <div className="">
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                         label={<><span>School Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                        type="text"
                                                                                        name="school_name"
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        disabled={this.state.schoolAdmin}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        label={<><span>Category</span><span className='formmandatorycolor'> *</span></>}
                                                                                        name="category"
                                                                                        select
                                                                                        component={TextField}
                                                                                        className="textfield__input"
                                                                                        fullwidth="true"
                                                                                        disabled={this.state.schoolAdmin}
                                                                                    >
                                                                                        {commonCategoryList.map((item:any) => (
                                                                                            <MenuItem value={item.value}>{item.value}</MenuItem>
                                                                                        ))}
                                                                                    </Field>
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Name</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="nameSchool"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Designation</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="designationSchool"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="text"
                                                                                            name="phoneNumber"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            label={<><span>Contact Person Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                                            type="email"
                                                                                            name="email_id"
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 p-b-5 p-t-5">
                                                                            <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                                                <FormGroup>
                                                                                    <Field
                                                                                        name="addressSchool"
                                                                                        component={TextField}
                                                                                        label={<><span>Address</span><span className='formmandatorycolor'> *</span></>}
                                                                                        className="textfield__input"
                                                                                        rows="1"
                                                                                        disabled={false}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="time"
                                                                                            name="startTime"
                                                                                            label={<><span>Start Time</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="time"
                                                                                            name="endTime"
                                                                                            label={<><span>End Time</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            type="text"
                                                                                            name="school_coverage_area"
                                                                                            label={<><span>School Coverage Area(In Meters)</span><span className='formmandatorycolor'> *</span></>}
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            InputLabelProps={{
                                                                                                shrink: true,
                                                                                            }}
                                                                                            />
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            name="acadamicStartMonth"
                                                                                            select
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            label={<><span>Academic Start Month</span><span className='formmandatorycolor'> *</span></>}
                                                                                            fullwidth="true"
                                                                                            disabled={false}
                                                                                            onChange={(e:any, value:any) => {
                                                                                                setFieldValue('acadamicStartMonth', e.target.value)
                                                                                                const currentMonthIndex = MONTHLIST.findIndex(month => month.code === e.target.value);
                                                                                                let nextMonthCode = ''
                                                                                                if (currentMonthIndex !== -1) {
                                                                                                    const nextMonthIndex = (currentMonthIndex + 11) % MONTHLIST.length;
                                                                                                    nextMonthCode = MONTHLIST[nextMonthIndex].code;
                                                                                                }
                                                                                                setFieldValue('acadamicEndMonth', nextMonthCode)
                                                                                              }} 
                                                                                        >
                                                                                            {MONTHLIST.map(item => (
                                                                                                <MenuItem value={item.code}>{item.code}</MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 p-t-20">
                                                                            <div>
                                                                                <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                                                    <FormGroup>
                                                                                        <Field
                                                                                            name="acadamicEndMonth"
                                                                                            select
                                                                                            component={TextField}
                                                                                            className="textfield__input"
                                                                                            label={<><span>Academic End Month</span><span className='formmandatorycolor'> *</span></>}
                                                                                            fullwidth="true"
                                                                                            disabled={true}
                                                                                        >
                                                                                            {MONTHLIST.map(item => (
                                                                                                <MenuItem value={item.code}
                                                                                                disabled={item.code === values.acadamicStartMonth}>{item.code}</MenuItem>
                                                                                            ))}
                                                                                        </Field>
                                                                                    </FormGroup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-3 mb-3">
                                                                        {  this.state.isLoaded && position ? 
                                                                       
                                                                        <GoogleMap
                                                                            mapContainerStyle={containerStyle}
                                                                            center={position}
                                                                            zoom={14}
                                                                            onLoad={this.onLoad}
                                                                            onUnmount={this.onUnmount}
                                                                        >
                                                                            <Marker
                                                                                position={position}
                                                                                onDragEnd={(e) => this.onMarkerDragEnd({ e: e })}
                                                                                draggable={true}
                                                                            />
                                                                            <></>
                                                                        </GoogleMap>
                                                                        :null}
                                                                        
                                                                    </div>
                                                                    <div className="text-right mb-3 mr-2 mt-4">
                                                                        <Button 
                                                                        className="btn btn-pink mr-1 ml-1" 
                                                                        type="submit"
                                                                        disabled={isSchoolEditEmpty || isValidating || checkStatus || !!(errors.acadamicEndMonth && touched.acadamicEndMonth) 
                                                                            || !!(errors.acadamicStartMonth && touched.acadamicStartMonth)||
                                                                            !!(errors.endTime && touched.endTime) ||  
                                                                            !!(errors.startTime && touched.startTime) || 
                                                                            !!(errors.addressSchool && touched.addressSchool) || 
                                                                            !!(errors.email_id && touched.email_id) || 
                                                                            !!(errors.phoneNumber && touched.phoneNumber) || 
                                                                            !!(errors.designationSchool && touched.designationSchool) || 
                                                                            !!(errors.nameSchool && touched.nameSchool) || 
                                                                            !!(errors.category && touched.category) || 
                                                                            !!(errors.school_name && touched.school_name ||
                                                                            !!(errors.school_coverage_area && touched.school_coverage_area))}>Submit</Button>
                                                                        <Link to="/school" 
                                                                        style={{ display: this.state.acadamicAdmin ? "inline-block" : "none" }}>
                                                                        <Button className="btn btn-default mr-1 ml-1">Cancel</Button></Link>
                                                                        <Link to="/view_school" 
                                                                        style={{ display: this.state.schoolAdmin ? "inline-block" : "none" }}>
                                                                        <Button className="btn btn-default mr-1 ml-1">Cancel</Button>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                    }
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <div><SpinnerLoader /></div>}
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        );
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = ({ schools }: RootState, ownProps: RouteComponentProps<OwnPropsParams>) => {
    return {
        errorMessage: schools.errors,
        loading: schools.loading,
        schoolData: schools.items[Number(ownProps.match.params.id)],
        schoolEditDetails: schools.getSchoolEdit,
        commonCategoryList:schools.schoolCategoryList
    };
};
export default connect(
    mapStateToProps, { editSchool, fetchSchool, commonGet }
)(withMyHook(EditSchool));
