import React, { Component } from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb';
import { RouteComponentProps } from 'react-router';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { stopYearData, stopSubComponentYear } from '../../store/profile/Actions';
import Cookies from 'universal-cookie';
import { fetchPsychometricReportById } from '../../store/psychometric/Actions';
import { option, plugin, overalloption, horizontalBaroption, pieOption } from "./Types";
import { Bar, HorizontalBar, Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { Button } from "@material-ui/core";

interface PropsStudentQuestionView extends RouteComponentProps<OwnPropsParams> {
  getChangeYearData?: any;
  stopYearDate?: any;
  stopYearData: () => any;
  stopSubComponentYear: (postValue: any) => any;
  fetchPsychometricReportById: (postValue: any) => any;
  loading?: any;
  psychometricReport?: any;
  psychometricDataStorage?: any;
}
const mystyle: any = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial"
};

interface StateTypes {
  psychometricReportDetail: any;
}
export class StudentPsychometricReport extends Component<PropsStudentQuestionView, StateTypes> {
  cookies: any = new Cookies();
  constructor(props: any) {
    super(props);
    this.state = {
      psychometricReportDetail: [],
    }
  }

  componentDidMount(): void {
    this.getCurrectYear()
  }

  componentWillUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData)
      const postValue: any = {
        status: true
      }
      this.props.stopSubComponentYear(postValue)
    }
  }
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData()
    }
  }
  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.getStudentReport(getYear)
    } else {
      this.getStudentReport(this.props.getChangeYearData)
    }
  }
  getStudentReport(getYear?: any) {
    const { psychometricDataStorage } = this.props;
    const value: any = {
      student_id: this.props.match.params.id,
      academic_year: getYear,
      unique_id: psychometricDataStorage.unique_id
    }
    this.props.fetchPsychometricReportById(value).then((res: any) => {
      const { psychometricReport } = this.props;
      this.setState({ psychometricReportDetail: psychometricReport });
      window.scrollTo(0, 0);
    });
  }

  handlePrint() {
    window.print();
  };


  render() {
    const { loading, psychometricReport } = this.props;
    const { psychometricReportDetail } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const styleCSS = { display: "flex", alignItems: "center", width: "100%", justifyContent: "space-around", height: "100%" };
    const rowCSS = { height: "100%" };
    let count: any;
    count = psychometricReport?.overall_report?.overall_intelligence_value
    let type: any;
    type = psychometricReport?.overall_report?.overall_intelligence_type
    return (
      <div>
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["Student Report Psychometric"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Student Report Psychometric"]}
                baseName={["Psychometric"]}
                baseURL={["psychometric"]}
              />
              {
                psychometricReportDetail ? (<>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-topline-red">
                        <div className="card-body no-padding height-9">
                          {psychometricReport ?
                            <div className="row">
                              <div className="col-md-2">
                                <div className="row mt-2">
                                  <div className="ml-3 d-flex">
                                    {
                                      <div>
                                        <button className="mdl-button
                                                            mdl-js-button
                                                            mdl-button--fab
                                                            mdl-button--mini-fab
                                                            ml-3 mt-3 btn-info"
                                        >{
                                            psychometricReport.student_name ?
                                              <span >{psychometricReport.student_name.charAt(0)}</span>
                                              : null}
                                        </button>
                                      </div>
                                    }

                                    <div>
                                      <div
                                        className="text-center"
                                      >
                                        <h3 className="my-0 pointer titleCapitalize">
                                          {psychometricReport.student_name}</h3>
                                        <span>({psychometricReport.grade_standard})</span>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-topline-red">
                        <div className="card-body no-padding height-9">
                          {psychometricReport ?
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row mt-2">
                                  <div className="ml-3 d-flex">

                                    <div
                                      className="text-center"
                                    >
                                      <h3>{psychometricReport.questionnaire_name}</h3>
                                      <span>{psychometricReport.questionnaire_description}</span>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="card card-topline-red h-100">
                        <div className="card-head">
                          <header>
                            <h4>Student Brain Activity </h4></header>
                          <div className="tools">
                          </div>
                        </div>
                        <div className="row" style={rowCSS}>
                          {psychometricReport && psychometricReport?.overall_report ?
                            <div style={styleCSS} className="text-center">
                              {/* <div>
                                <div style={{ textAlign: 'center' }}>Left</div>
                                <button className="btn btn-xs btn-circle btn-pink cursorPoint" style={{ fontSize: '18px' }}>{psychometricReport.overall_report.overall_left_brain_activity} %</button>
                              </div> */}
                              <div>
                                <img src={psychometricReport.overall_report.intelligence_image_url} alt="" style={{ width: '650px', height: '848px' }} />
                                <div className="psychometric-my-quotients-title">My 4 QUOTIENTS</div>
                                <div className="psychometric-my-quotients-types">
                                  {
                                    psychometricReport.overall_report.overall_intelligence_type && psychometricReport.overall_report.overall_intelligence_type.length !== 0 ?
                                      psychometricReport.overall_report.overall_intelligence_type.map((items: any, i: any) => (
                                        <div className={`psychometric-my-quotients-type ${items}`}>
                                          <div className="psychometric-my-quotients-type-title">
                                            {items}
                                          </div>
                                          <div className="psychometric-my-quotients-type-box">
                                            {psychometricReport.overall_report.overall_intelligence_value[i]}
                                          </div>
                                        </div>
                                      )) :
                                      null
                                  }</div>
                              </div>
                              {/* <div>
                                <div style={{ textAlign: 'center' }}>Right</div>
                                <button className="btn btn-xs btn-circle btn-pink cursorPoint" style={{ fontSize: '18px' }}>{psychometricReport.overall_report.overall_right_brain_activity} %</button>
                              </div> */}
                            </div>
                            : null}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="card card-topline-red h-100">
                        <div className="card-head">
                          <header>
                            <h4>Career Guidance </h4></header>
                          <div className="tools">
                          </div>
                        </div>
                        <div className="row p-3" style={rowCSS}>
                          {psychometricReport && psychometricReport?.overall_report?.career_guidance_data ?
                            <div className="col-12 row">
                              {psychometricReport && psychometricReport?.overall_report?.career_guidance_data && psychometricReport.overall_report.career_guidance_data.length !== 0 ?
                                psychometricReport.overall_report.career_guidance_data.map((items: any) => (
                                  <div className="col-xl-4 col-md-6 col-12">
                                    <div>
                                      <div className={`title p-10 pl-3`}>
                                        {items.category}
                                      </div>
                                      <div className='w-75 cg-row'>
                                        <div className="cg-content">
                                          {items.sub_category.map((item: any) => (
                                            <span className={`sub-category ${item.intelligence_color_name}`} style={{ backgroundColor: `${item.intelligence_color_code}` }}>{item.name}</span>
                                          ))}
                                        </div>
                                        <div className="cg-image-container">
                                          <img src='../assets/img/target-arrow.png' alt="" className="career-guidance" />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                )) :
                                null
                              }
                              {psychometricReport && psychometricReport?.overall_report?.career_guidance_color_code && psychometricReport.overall_report.career_guidance_color_code.length !== 0 ?
                                <div className="m-5 row">{
                                  psychometricReport.overall_report.career_guidance_color_code.map((items: any) => (
                                    <div className="col-xl-3 col-md-4 col-6 p-3">
                                      <div className="d-flex  align-items-center">
                                        <div className={`career_guidance_circle ${items.color_name}`} style={{ backgroundColor: `${items.color_code}` }}></div>
                                        <div className={`title p-10 pl-3`}>
                                          {items.category_name}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }</div> :
                                null
                              }
                            </div>
                            : null}

                        </div>
                      </div>
                    </div>
                  </div>
                  <header><h3>Your Results Snapshot</h3></header>
                  {psychometricReport?.overall_report && psychometricReport?.overall_report?.intelligence_data?.intelligence_graph_data?.intelligence_type &&
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="card card-topline-red h-100">
                          <div className="row  justify-content-center align-items-center">
                              <div className="text-intelligences-desc m-5">
                                <div dangerouslySetInnerHTML={{__html: psychometricReport.overall_report.intelligence_data.overall_intelligence_description}} />
                              </div>
                            <div className="col-lg-6">
                              <div style={{fontSize:"18px",margin:"10px"}}><div dangerouslySetInnerHTML={{__html: psychometricReport.overall_report.intelligence_data.top_two_intelligence_data}} /></div>
                              <Bar
                                data={{
                                  labels: psychometricReport.overall_report.intelligence_data.intelligence_graph_data.intelligence_type,
                                  datasets: [
                                    {
                                      backgroundColor: psychometricReport.overall_report.intelligence_data.intelligence_graph_data.color_code,
                                      borderColor: psychometricReport.overall_report.intelligence_data.intelligence_graph_data.color_code,
                                      borderWidth: 1,
                                      label: psychometricReport.overall_report.intelligence_data.intelligence_graph_data.intelligence_type,
                                      data: psychometricReport.overall_report.intelligence_data.intelligence_graph_data.intelligence_percentage
                                    }
                                  ]
                                }}
                                options={overalloption}
                                plugins={[plugin]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {psychometricReport?.overall_report && psychometricReport?.overall_report?.overall_category_report_data?.overall_category_graph_data?.category_type &&
                    <div>
                       <header><h3>Your Multiple Intelligences</h3></header>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="card card-topline-red h-100">
                          <div className="row  justify-content-center align-items-center">
                            <div className="col-lg-6">
                              <div className="text-intelligences-desc m-5" dangerouslySetInnerHTML={{__html: psychometricReport.overall_report.overall_category_report_data.top_three_intelligence_data}} />
                              <div className="text-intelligences-desc m-5" dangerouslySetInnerHTML={{__html: psychometricReport.overall_report.overall_category_report_data.overall_category_description}} />
                            </div>
                            <div className="col-lg-6">
                              <Pie
                                data={{
                                  labels: psychometricReport.overall_report.overall_category_report_data.overall_category_graph_data.category_type,
                                  datasets: [
                                    {

                                      backgroundColor: psychometricReport.overall_report.overall_category_report_data.overall_category_graph_data.category_color,
                                      borderColor: psychometricReport.overall_report.overall_category_report_data.overall_category_graph_data.category_color,
                                      borderWidth: 1,
                                      label: psychometricReport.overall_report.overall_category_report_data.overall_category_graph_data.category_data,
                                      data: psychometricReport.overall_report.overall_category_report_data.overall_category_graph_data.category_value,
                                    }
                                  ]
                                }} 
                                 options={pieOption}
                                 plugins={[plugin]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  }
                  {psychometricReport?.overall_report && psychometricReport?.overall_report?.category_intelligence_graph_data && Object.keys(psychometricReport.overall_report?.category_intelligence_graph_data).map((graph_data: any, key: any) => {
                    const val = psychometricReport.overall_report?.category_intelligence_graph_data[graph_data]
                    const description = psychometricReport.overall_report?.category_intelligence_value[graph_data].description
                   const colorcode = psychometricReport.overall_report.intelligence_gender_color_code
                    return (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="card card-topline-red h-100">
                          <div className="card-head">
                                  <header>
                                    <h4>About {graph_data} Intelligence</h4></header>
                                  <div className="tools">
                                  </div>
                                </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-12 p-5">
                                <header>
                                  <h4> </h4></header>
                                <HorizontalBar
                                  data={{
                                    labels: val.gender,
                                    type: 'horizontalBar',
                                    datasets: [
                                      {
                                      label: val.gender,
                                      fill:false,
                                      data: val.value,
                                      backgroundColor:colorcode,
                                      borderColor: colorcode,
                                      borderWidth: 1,
                                  }
                                    ]
                                  }}
                                  options={horizontalBaroption}
                                />
                              </div>
                              <div className="col-lg-6 h-100">
                                <div className="m-5 text-intelligences-desc">
                                  {description}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }

                  <div className="users-report-header"><header><h3>Conducted Users Reports</h3></header></div>
                  {psychometricReport?.report_data && psychometricReport.report_data.map((reportData: any) => {
                    const { intelligence_type, intelligence_value, color_code } = reportData.report_data
                    return (
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="card card-topline-red h-100">
                            <div className="row">
                              <div className="col-lg-6 col-md-12 p-5">
                                <header>
                                  <h4>{reportData.conducted_user_data.user_name} - {reportData.conducted_user_data.role == 'class_teacher' ? 'Class Teacher' :
                                    reportData.conducted_user_data.role == 'teacher' ? 'Teacher' : 'Parent'} </h4></header>
                                <Bar
                                  data={{
                                    labels: type,
                                    datasets: [
                                      {
                                        backgroundColor: color_code,
                                        borderColor: color_code,
                                        borderWidth: 2,
                                        label: intelligence_type,
                                        data: intelligence_value
                                      }
                                    ]
                                  }}
                                  options={option}
                                  plugins={[plugin]}
                                />
                              </div>
                              <div className="col-lg-6 h-100">
                                <div className="card-head">
                                  <header>
                                    <h4>Student Brain Activity </h4></header>
                                  <div className="tools">
                                  </div>
                                </div>
                                <div className="row justify-content-center align-items-center" style={rowCSS}>
                                  <div style={styleCSS}>
                                    <div>
                                      <div style={{ textAlign: 'center' }}>Left</div>
                                      <button className="btn btn-xs btn-circle btn-pink cursorPoint" style={{ fontSize: '18px' }}>{reportData.left_brain_activity} %</button>
                                    </div>
                                    <div><img src='../assets/img/brain-bulb.jpg' alt="" className="studentImage" /></div>
                                    <div>
                                      <div style={{ textAlign: 'center' }}>Right</div>
                                      <button className="btn btn-xs btn-circle btn-pink cursorPoint" style={{ fontSize: '18px' }}>{reportData.right_brain_activity} %</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                  <div className="text-right mb-3 mr-2 mt-4">
                    <Button
                      className="btn btn-pink mr-1 ml-1"
                      type="button"
                      onClick={this.handlePrint}>
                      Print Page
                    </Button>
                  </div>
                </>) : null}
            </div>
          </div>
        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    )
  }
}

interface OwnPropsParams {
  id: string;
}
const mapStateToProps = (state: any) => {
  return {
    loading: state.psychometric.loading,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    psychometricReport: state.psychometric.psychometricReport,
    psychometricDataStorage: state.psychometric.psychometricDataStorage
  }
}

export default connect(mapStateToProps, {
  stopYearData, stopSubComponentYear,
  fetchPsychometricReportById,
})(StudentPsychometricReport)

