class FileUploadPlugin {
    loader: any;
    props: any;
    url: any;
    constructor(loader: any, props: any, url: any) {
        this.loader = loader;
        this.props = props;
        this.url = url;
    }
    async upload() {
        return this.loader.file
        .then(async (file: any) => {
            const formData = new FormData();
            formData.append('file', file);
            return await this.props.editorImageUpload(formData, this.url).then((res: any) => {
                return res;
             });
        })
        .then((response: any) => {
            return {
                default: `${process.env.REACT_APP_API_URL}${response}`
            };
        });
    }
}


export default FileUploadPlugin;
