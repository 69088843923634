import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import { Field, Formik, Form, FieldArray, ErrorMessage } from "formik";
import { Button, MenuItem, FormGroup } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { get } from "lodash";
import { fetchBluePrintGradeDropdownGet, fetchBluePrintSubjectDropdownGet, 
        fetchBluePrintExamTypeDropdownGet, fetchBluePrintLessonDropdownGet,
        fetchBluePrintDifficultyLevelDropdownGet, fetchBluePrintQuestionTypeDropdownGet,
        addBluePrintGet } from '../../store/QuestionBank/Actions';
import { getYearData, stopYearData } from '../../store/profile/Actions'
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { schema } from "./QuestionBankValidation";

export type Props = {
    loading: boolean;
    getChangeYearData?: any;
    stopYearDate?: any;
    bluePrintGradeDropdown?: any;
    bluePrintSubjectDropdown?: any;
    bluePrintExamTypeDropdown?: any;
    bluePrintLessonDropdown?: any;
    bluePrintDifficultyLevelDropdown?: any;
    bluePrintQuestionTypeDropdown?: any;
    stopYearData: () => any;
    fetchBluePrintGradeDropdownGet: (data: any) => any;
    fetchBluePrintSubjectDropdownGet: (data: any) => any;
    fetchBluePrintExamTypeDropdownGet: (data: any) => any;
    fetchBluePrintLessonDropdownGet: (data: any) => any;
    fetchBluePrintDifficultyLevelDropdownGet: ( ) => any;
    fetchBluePrintQuestionTypeDropdownGet: ( ) => any;
    addBluePrintGet: (data: any) => any;
}

interface StateTypes {
    academicYear?: any;
    grade?: any;
    gradeId?: any;
    subject?: any;
    examTypes?: any;
    total_marks?: any;
    question_type?: any;
    question_type_marks?: any;
    lessons?: any;
    difficulty_level?: any;
    added_total_marks?: any;
}
const initialValues = {
    blueprint_name: "",
    blueprint_desc: "",
    question_type: "",
    select_grade: "",
    select_subject: "",
    select_exam_type: "",
    total_errors: "",
    blueprintlist: [
        {
            question_type: "",
            question_type_marks: "",
            lessons: "",
            difficulty_level: "",
            no_of_question: "",
            lesson_total_marks: 0,
        },
    ],
};

export class AddBluePrint extends Component<Props, StateTypes> {
    formik: any;
    constructor(props: any) {
        super(props);
        this.state = {
            grade: [],
            gradeId:"",
            subject: [],
            examTypes: [],
            total_marks: 0,
            question_type: [],
            question_type_marks: [],
            lessons: [],
            difficulty_level:[],
            academicYear: null,
            added_total_marks: 0,
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getCurrectYear();
    }
    componentWillUpdate(nextProps: any, nextState: any) {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.getCurrectYear(this.props.getChangeYearData);
        }
    }
    componentDidUpdate() {
        const { stopYearDate } = this.props;
        if (stopYearDate === true) {
            this.props.stopYearData();
        }
    }

    getCurrectYear(getYear?: any) {
        if (getYear && getYear !== undefined && getYear !== null) {
            this.getBluePrintDetails(getYear);
            this.setState({ academicYear: getYear });
        } else {
            this.getBluePrintDetails(this.props.getChangeYearData);
            this.setState({ academicYear: this.props.getChangeYearData });
        }
    }

    getBluePrintDetails(getYear?: any) {
        const getData = {
            academic_year: getYear,
          }
          this.props.fetchBluePrintGradeDropdownGet(getData).then((res: any) => {
            const { bluePrintGradeDropdown } = this.props;
            this.setState({ grade: bluePrintGradeDropdown });
          });
          this.props.fetchBluePrintExamTypeDropdownGet(getData).then((res: any) => {
            const { bluePrintExamTypeDropdown } = this.props;
            this.setState({ examTypes: bluePrintExamTypeDropdown });
          });
          this.props.fetchBluePrintDifficultyLevelDropdownGet().then((res: any) => {
            const { bluePrintDifficultyLevelDropdown } = this.props;
            this.setState({ difficulty_level: bluePrintDifficultyLevelDropdown });
          });
          this.props.fetchBluePrintQuestionTypeDropdownGet( ).then((res: any) => {
            const { bluePrintQuestionTypeDropdown } = this.props;
            this.setState({ question_type: bluePrintQuestionTypeDropdown });
          });
    }

    updateTotalMarks = (e: any, getValue?: any) => {
        const { examTypes } = this.state;
        const value = examTypes.find((item: any) => item.uid === getValue);
        if (value) {
            this.setState({ total_marks: value.total_marks })
        }
    };

    getMarkOptions = (typeName: any) => {
        const { question_type } = this.state;
        const questionType = question_type.find((type: any) => type.id === typeName);
        return questionType ? questionType.marks : [];
    };

    getSubjectDropdown = (value: any) => {
        const { academicYear } = this.state;
        const getData = {
            academic_year: academicYear,
            internal_grade_id:value
          }
        this.props.fetchBluePrintSubjectDropdownGet(getData).then((res: any) => {
            const { bluePrintSubjectDropdown } = this.props;
            this.setState({ gradeId: value,subject: bluePrintSubjectDropdown });
          });
    }

    getLessonDropdown = (value: any) => {
        const { academicYear, gradeId } = this.state;
        const getData = {
            academic_year: academicYear,
            internal_grade_id:gradeId,
            internal_subject_id:value
          }
        this.props.fetchBluePrintLessonDropdownGet(getData).then((res: any) => {
            const { bluePrintLessonDropdown } = this.props;
            this.setState({ lessons: bluePrintLessonDropdown });
          });
    }
    
    updateQuestionTotalMarks = (values: any, marks: any, index:any) => {
        const updatedMarks = values.blueprintlist.map((m:any, i:any) =>
        i === index ? parseInt(marks) : m.lesson_total_marks
      );
      const newTotalMarks = updatedMarks.reduce(
        (acc:any, curr:any) => acc + (isNaN(curr) ? 0 : parseInt(curr)),
        0
      );
      this.setState({ added_total_marks: newTotalMarks })
    }
    render() {
        const { grade, subject, examTypes, total_marks, question_type, lessons, difficulty_level, added_total_marks, academicYear } = this.state;
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return (
            <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['Create BluePrint']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['Create BluePrint']} />

                        <div className="row">
                            <div className="col-md-12">
                                <Formik
                                    ref={node => this.formik = node}
                                    initialValues={initialValues}
                                    validationSchema={schema}
                                    validate={(values) => {
                                        const errors: { [key: string]: any } = {};
                                        if (total_marks <= 0) {
                                            errors.total_errors = 'Total Marks cannot be 0';
                                          }
                                        if (total_marks > 0 && total_marks !== added_total_marks) {
                                          errors.total_errors = 'The added marks should match the total marks.';
                                        }

                                        const combinations = new Set();
                                        if (values.blueprintlist) {
                                          values.blueprintlist.forEach((item:any, index:any) => {
                                            const combination = `${item.question_type}-${item.question_type_marks}-${item.lessons}-${item.difficulty_level}`;
                                
                                            if (combinations.has(combination)) {
                                              errors.blueprintlist = errors.blueprintlist || [];
                                              errors.blueprintlist[index] = { question_type: 'Combination already exists', 
                                                                        question_type_marks: 'Combination already exists', 
                                                                        lessons: 'Combination already exists',
                                                                        difficulty_level: 'Combination already exists',
                                                                        };
                                            } else {
                                              combinations.add(combination);
                                            }
                                          });
                                        }
                                        
                                
                                        return errors;
                                      }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        // console.log(values)
                                        const getBluePrintValue: any = {
                                            blueprint_name: values.blueprint_name,
                                            blueprint_desc: values.blueprint_desc,
                                            academic_year: academicYear,
                                            internal_grade_id: parseInt(values.select_grade),
                                            internal_subject_id: parseInt(values.select_subject),
                                            exam_type_id: values.select_exam_type,
                                            blueprint_details: values.blueprintlist.map((item:any) => ({
                                                "difficulty_level_id": item.difficulty_level,
                                                "question_type_id": item.question_type,
                                                "question_type_marks": item.question_type_marks,
                                                "wizdomwaves_lesson_id": item.lessons,
                                                "no_of_question": parseInt(item.no_of_question),
                                                "aggregate_mark": item.lesson_total_marks
                                            }))
                                        };
                                        this.props.addBluePrintGet(getBluePrintValue).then((res: any) => {

                                        })
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValidating,
                                        dirty,
                                        touched,
                                        handleReset,
                                        handleSubmit,
                                        handleChange,
                                        setFieldValue,
                                    }) => {

                                        return (
                                            <Form>

                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>BluePrint Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="blueprint_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>BluePrint Desc</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="blueprint_desc"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100 mb-4">
                                                            <Field
                                                                label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>}
                                                                name="select_grade"
                                                                select
                                                                component={TextField}
                                                                onChange={(e: any, value: any) => {
                                                                    this.getSubjectDropdown(e.target.value);
                                                                    setFieldValue(
                                                                        "select_grade",
                                                                        e.target.value
                                                                    );
                                                                    setFieldValue("select_subject", ""); 
                                                                    if (e.target.value !== values.select_subject) {
                                                                        values.blueprintlist.forEach((value: any, index: number) => {
                                                                            setFieldValue(`blueprintlist.[${index}].lessons`, "");
                                                                        }); 
                                                                        this.setState({ lessons:[] }); 
                                                                    }
                                                                }}
                                                            >
                                                                {grade && grade.length > 0 && grade.map((item: any) => (
                                                                    <MenuItem value={item.internal_grade_id}>
                                                                        {item.internal_grade_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100 mb-4">
                                                            <Field
                                                                label={<><span>Subject</span><span className='formmandatorycolor'> *</span></>}
                                                                name="select_subject"
                                                                select
                                                                component={TextField}
                                                                onChange={(e: any, value: any) => {
                                                                    this.getLessonDropdown(e.target.value);
                                                                    setFieldValue(
                                                                        "select_subject",
                                                                        e.target.value
                                                                    );
                                                                    if (e.target.value !== values.select_subject) {
                                                                        values.blueprintlist.forEach((value: any, index: number) => {
                                                                            setFieldValue(`blueprintlist.[${index}].lessons`, "");
                                                                        });
                                                                        
                                                                    }
                                                                }}
                                                            >
                                                                {subject && subject.length > 0 && subject.map((item: any) => (
                                                                    <MenuItem value={item.internal_subject_id}>
                                                                        {item.internal_subject_name}
                                                                    </MenuItem>
                                                                ))}

                                                            </Field>
                                                        </FormGroup>
                                                    </div>


                                                    <div className="col-md-4">
                                                        <FormGroup className="w-100 mb-4">
                                                            <Field
                                                                label={<><span>Exam Type</span><span className='formmandatorycolor'> *</span></>}
                                                                name="select_exam_type"
                                                                select
                                                                component={TextField}
                                                                onChange={(e: any, value: any) => {

                                                                    setFieldValue(
                                                                        "select_exam_type",
                                                                        e.target.value
                                                                    );
                                                                    this.updateTotalMarks(e, e.target.value);
                                                                }}
                                                            >
                                                                {examTypes && examTypes.length > 0 && examTypes.map((item: any) => (
                                                                    <MenuItem value={item.uid} data-action={item.total_marks} >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Total Marks</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="total_marks"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                value={total_marks}
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <FieldArray
                                                            name="blueprintlist"
                                                            render={({ insert, remove, push }) => (
                                                                <>
                                                                    {values.blueprintlist.length > 0 &&
                                                                        values.blueprintlist.map(
                                                                            (blueprint: any, index: any) => (
                                                                                <div className="row" key={index}>
                                                                                    <div className="col-md-12">
                                                                                        <div className="card-box mt-4">
                                                                                            <div className="card-body pb-5 row">
                                                                                                <div className="col-md-12 text-right">
                                                                                                    <button
                                                                                                        className=""
                                                                                                        type="button"
                                                                                                        title="Delete Question"
                                                                                                        onClick={() =>{
                                                                                                            remove(index)
                                                                                                            this.updateQuestionTotalMarks(values, 0, index)
                                                                                                        }
                                                                                                           
                                                                                                        }
                                                                                                    >
                                                                                                        <span className="deleteIcon">
                                                                                                            <i
                                                                                                                className="fa fa-trash"
                                                                                                                aria-hidden="true"
                                                                                                            ></i>
                                                                                                        </span>
                                                                                                    </button>
                                                                                                </div>


                                                                                                <div className="col-md-2">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>Question Type</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            select
                                                                                                            name={`blueprintlist.${index}.question_type`}
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            disabled={false}
                                                                                                            onChange={(e: any) => {
                                                                                                                setFieldValue(`blueprintlist.${index}.question_type_marks`, '');
                                                                                                                setFieldValue(`blueprintlist.${index}.question_type`, e.target.value);
                                                                                                            }}
                                                                                                        >

                                                                                                            {question_type && question_type.length > 0 && question_type.map((item: any) => (
                                                                                                                <MenuItem value={item.id} >
                                                                                                                    {item.name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <div className="col-md-1">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>Marks</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`blueprintlist.${index}.question_type_marks`}
                                                                                                            select
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            disabled={false}
                                                                                                            onChange={(e: any) => {
                                                                                                                setFieldValue(`blueprintlist.${index}.question_type_marks`, e.target.value);

                                                                                                                handleChange(e);
                                                                                                                // Update the value based on the selected questionType

                                                                                                                if (blueprint.no_of_question) {
                                                                                                                    const marks:any = parseInt(e.target.value) * parseInt(blueprint.no_of_question)
                                                                                                                    values.blueprintlist[index].lesson_total_marks = marks
                                                                                                                    this.updateQuestionTotalMarks(values, marks, index)
                                                                                                                }


                                                                                                            }}
                                                                                                        >
                                                                                                            {this.getMarkOptions(blueprint.question_type).map((item: any) => (

                                                                                                                <MenuItem value={item.value} >
                                                                                                                    {item.label}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <div className="col-md-4">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>Lessons</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`blueprintlist.${index}.lessons`}
                                                                                                            select
                                                                                                            component={TextField}
                                                                                                            className="textfield__input w-100"
                                                                                                            disabled={false}
                                                                                                        >
                                                                                                            {lessons && lessons.length > 0 && lessons.map((item: any) => (
                                                                                                                <MenuItem value={item.uid} >
                                                                                                                    {item.name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <div className="col-md-2">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>Difficulty Level</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`blueprintlist.${index}.difficulty_level`}
                                                                                                            select
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            disabled={false}
                                                                                                        >
                                                                                                            {difficulty_level && difficulty_level.length > 0 && difficulty_level.map((item: any) => (
                                                                                                                <MenuItem value={item.uid} >
                                                                                                                    {item.name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Field>
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <div className="col-md-2">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>No. Of Question</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`blueprintlist.${index}.no_of_question`}
                                                                                                            type="text"
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            onChange={(e: any) => {
                                                                                                                setFieldValue(`blueprintlist.${index}.no_of_question`, e.target.value);
                                                                                                                handleChange(e);
                                                                                                                const marks:any = parseInt(e.target.value) * parseInt(blueprint.question_type_marks)
                                                                                                                values.blueprintlist[index].lesson_total_marks = marks ? marks : 0

                                                                                                              this.updateQuestionTotalMarks(values, marks, index)

                                                                                                            }}
                                                                                                            disabled={false}
                                                                                                        />
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                                <div className="col-md-1">
                                                                                                    <FormGroup className="w-100">
                                                                                                        <Field
                                                                                                            label={<><span>Marks</span><span className='formmandatorycolor'> *</span></>}
                                                                                                            name={`blueprintlist.${index}.lesson_total_marks`}
                                                                                                            type="text"
                                                                                                            component={TextField}
                                                                                                            className="textfield__input"
                                                                                                            disabled={true}
                                                                                                            onChange={handleChange}
                                                                                                        />
                                                                                                    </FormGroup>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    <div className="col-md-12 text-center">
                                                                        <Link
                                                                            to={"#"}
                                                                            title="Add Question"
                                                                            className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab mdl-button--colored margin-right-10"
                                                                            data-upgraded=",MaterialButton"
                                                                            onClick={() =>
                                                                                push({
                                                                                    question_type: "",
                                                                                    question_type_marks: "",
                                                                                    lessons: "",
                                                                                    no_of_question: "",
                                                                                    difficulty_level: "",
                                                                                    lesson_total_marks:""
                                                                                })
                                                                            }
                                                                        >
                                                                            <i className="material-icons">add</i>
                                                                        </Link>
                                                                        <div>Total Marks: {added_total_marks}</div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-right mb-3 mr-2 mt-4">
                                                <ErrorMessage name="total_errors" component="div" className="errorcolor"/>

                                                <Button
                                                    className="btn btn-pink mr-1 ml-1" 
                                                    type="submit">Submit</Button>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        getYear: state.profile.getYear,
        stopYear: state.profile.yearStatusFails,
        getChangeYearData: state.profile.getYear,
        bluePrintGradeDropdown: state.QuestionBank.bluePrintGradeDropdown,
        bluePrintSubjectDropdown: state.QuestionBank.bluePrintSubjectDropdown,
        bluePrintExamTypeDropdown: state.QuestionBank.bluePrintExamTypeDropdown,
        bluePrintLessonDropdown: state.QuestionBank.bluePrintLessonDropdown,
        bluePrintDifficultyLevelDropdown: state.QuestionBank.bluePrintDifficultyLevelDropdown,
        bluePrintQuestionTypeDropdown: state.QuestionBank.bluePrintQuestionTypeDropdown,
    }
}

export default connect(mapStateToProps, {
    stopYearData,
    fetchBluePrintGradeDropdownGet,
    fetchBluePrintSubjectDropdownGet,
    fetchBluePrintExamTypeDropdownGet,
    fetchBluePrintLessonDropdownGet,
    fetchBluePrintDifficultyLevelDropdownGet,
    fetchBluePrintQuestionTypeDropdownGet,
    addBluePrintGet
})(AddBluePrint)