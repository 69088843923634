import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommonLoader from '../../components/CommonLoader';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getYearData, stopYearData } from '../../store/profile/Actions'
import { fetchSuperuserLicenseDetailsGet, fetchAllLicensePartitionGet, deleteLicensePartition, fetchLicenseBySchoolGet } from '../../store/license/Actions';
import { LicensePartitionMenu } from '../../router/Roles';
import { CommonModel } from '../../components/CommonModel';
import { ModalView } from './ModalView';


export type Props = {
  getYear?: any;
  stopYear?: any;
  loading?: any;
  licensePartition?: any;
  licenseBySchool?: any;
  getChangeYearData?: any;
  licenseModuleDetails?: any;
  getYearData: (postvalue?: any) => any;
  stopYearData: () => any;
  fetchAllLicensePartitionGet: (getData?: any) => any;
  deleteLicensePartition: (postvalue?: any) => any;
  fetchLicenseBySchoolGet: (params?: any) => any;
  fetchSuperuserLicenseDetailsGet: (getData: any) => any;
}

interface StateTypes {
  academicYear?: any;
  records?: any;
  search: string,
  page_no?: any,
  recordPage?: any,
  hasMore: boolean,
  SortOrderData: string,
  OrderNameData: string,
  page?: any,
  showDelete?: boolean,
  showView?: boolean,
  selectedId?: any,
  schoolLicenseDetails?: any,
  allLicenseModule?: any;
}
export class LicensePartition extends Component<Props, StateTypes> {

  constructor(props: any) {
    super(props);
    this.state = {
      academicYear: '',
      records: [],
      search: '',
      hasMore: true,
      page: 1,
      page_no: 1,
      SortOrderData: '',
      OrderNameData: '',
      recordPage: 1,
      showDelete: false,
      showView: false,
      selectedId: '',
      schoolLicenseDetails: [],
      allLicenseModule: []
    }
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getLicensePartition();
    this.getCurrectYear();
  }
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.getLicensePartition(this.props.getYear)
      this.getCurrectYear(this.props.getYear);
    }
  }

  componentDidUpdate() {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.props.stopYearData()
    }
  }

  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear });
      this.getfetchSuperuserLicenseDetailsData(getYear)
    } else {
      this.setState({ academicYear: this.props.getChangeYearData });
      this.getfetchSuperuserLicenseDetailsData(this.props.getYear)
    }
  }

  getfetchSuperuserLicenseDetailsData(getYear?: any) {
    // this.props.fetchSuperuserLicenseDetailsGet({ academic_year: getYear }).then((res: any) => {
    //     const { licenseModuleDetails } = this.props;


    //     if(licenseModuleDetails.data){
    //       const licenseModule = licenseModuleDetails.data
    //       licenseModule.forEach((items:any)=> {
    //         if(items.module_name === 'psychometric_module'){

    //             items['bg_class'] = "bg-blue";
    //             items['icon_class'] = "fa fa-graduation-cap";
    //             items['title'] = "Psychometric";

    //         }else if(items.module_name === 'questionbank_module'){

    //             items['bg_class'] = "bg-orange";
    //             items['icon_class'] = "fa fa-users";
    //             items['title'] = "Questionbank";					

    //         }else if(items.module_name === 'student_module') {

    //             items['bg_class'] = "bg-success";
    //             items['icon_class'] = "fa fa-graduation-cap";
    //             items['title'] = "Student";

    //         }

    //       })
    //       this.setState({ allLicenseModule: licenseModule })
    //     }


    // });
  }


  getLicensePartition(getYear?: any) {
    this.getLicensePartitionDetails(getYear)
  }
  getLicensePartitionDetails(getYear?: any) {
   
    this.props.fetchAllLicensePartitionGet().then((res: any) => {
      const { licensePartition } = this.props;
      console.log(licensePartition)
      this.setState({ records: licensePartition.data })
    });
  }

  public showViewPopup = (getData: any) => {
    if (getData) {
      this.setState({ showView: true, schoolLicenseDetails: getData });
    }
  }

  onViewClose = () => {
    this.setState({ showView: false });
  }

  render() {
    const { loading } = this.props;
    const { records, showDelete, showView, schoolLicenseDetails, allLicenseModule } = this.state;
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    const postValue: any = {
      title: 'Please Confirm',
      action_status: 'delete',
      action_name: 'Questionare'
    }
    return (
      <div>
        <ModalView
          onState={showView}
          schoolLicenseDetails={schoolLicenseDetails}
          onCancel={this.onViewClose}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['License Partition']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['License Partition']} />
              <div className="row">
                <div className="col-md-12">
                  {records.length > 0 ?
                    <div className="row mt-5 w-100">
                      {records.map((items: any, index: any) => (
                        <div className="col-md-3">
                          <div className="card">
                            <div className="m-b-20">
                              <div className="doctor-profile">
                                <div id="licenseList" className={`license-header ${items.day_count < 0 ? "bg-b-red" : items.day_count > 31 ? "bg-b-green-dark" : " bg-b-orange-dark"}`}>
                                  <div className="pull-right">
                                  </div>
                                </div>
                                    <div className="user-img mb-2 bg-white flex flex-column days-left">
                                    <div className="days-left">
                                    {items.day_count >= 0 ? "Days Left" : "Expired"}
                                      </div>
                                      <div className="days-count">
                                        {items.day_count >= 0 ? items.day_count : 0}
                                      </div>
                                    </div>
                                <div>
                                  <div className="license-school-name">{items.school_name}</div>
                                  <div><p>{items.category}</p></div>
                                  <div><p>{items.acadamic_start_month} - {items.acadamic_end_month}</p></div>
                                  <div><p>{items.remaining_license} / {items.license_count}</p></div>
                                  <div><p>{items.start_date} to {items.end_date}</p></div>
                                </div>
                                <div className="profile-userbuttons">
                                  {items.additional_module.length > 0 ?
                                      <button className="btn btn-circle bg-blue btn-sm"
                                        title="View"><i className="fa fa-eye" aria-hidden="true"
                                          onClick={() => this.showViewPopup(items.additional_module)}></i></button> :
                                     null
                                    }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    : <CommonLoader />}



                </div>
              </div>
            </div>
          </div>
          <div style={loadingQuestionFull}><SpinnerLoader /></div>
        </div>
      </div>
    )
  }

}


const mapStateToProps = (state: any) => {
  return {
    loading: state.license.loading,
    getYear: state.profile.getYear,
    stopYear: state.profile.yearStatusFails,
    getChangeYearData: state.profile.getYear,
    licensePartition: state.license.licensePartition,
    license: state.license.license,
    licenseBySchool: state.license.licenseBySchool,
    licenseModuleDetails: state.license.licenseModuleDetails,
  }
}

export default connect(mapStateToProps, {
  fetchAllLicensePartitionGet,
  stopYearData,
  getYearData,
  deleteLicensePartition,
  fetchLicenseBySchoolGet,
  fetchSuperuserLicenseDetailsGet

})(LicensePartition)