import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import "./PsychometricStyle.scss";
import { stopYearData } from "../../store/profile/Actions";
import { fetchAgeGet, getQuestionnaireByAge, CreateQuestionnaireMapping, fetchActiveClassTeacher, fetchStudentListwithQuestionnaire } from "../../store/psychometric/Actions";
import { ModalView } from './ModalView';
import { ModalAlert } from './ModalAlert';
import { Field, Formik, Form } from "formik";
import { Button, MenuItem, FormGroup, Checkbox } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Switch from '@material-ui/core/Switch';
import CommonLoader from '../../components/CommonLoader';
import { FormControlLabel } from '@material-ui/core';
import {
  fetchGetAllClassList,
  fetchGetStudent,
} from '../../store/diary/Actions';
import { PsychometricStudentList } from '../../components/PsychometricStudentList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchTeachersPost } from '../../store/teacher/Actions';
import { LoadMoreType } from '../../components/type';
import { fetchMyClassListGet, fetchMySectionListGet } from '../../store/classes/Actions';
import { Link } from "react-router-dom";
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getTeacherActiveNameList } from '../../store/teacher/Actions';

import history from '../../History';
import { schema } from "./PsychometricValidation";
import { fetchLicenseBySchoolGet } from '../../store/license/Actions';


export type OwnQuestSetProps = {
  getYear?: any;
  getChangeYearData?: any;
  stopYearDate?: any;
  stopYearData: () => any;
  fetchGetAllClassList: (postPage: any) => any;
  fetchStudentListwithQuestionnaire: (getStudentList: any) => any;
  fetchTeachersPost: (getData: LoadMoreType) => any;
  fetchMyClassListGet: () => any;
  fetchMySectionListGet: (grade: any) => any;
  getQuestionnaireByAge: (getData: any) => any;
  fetchAgeGet: () => any;
  CreateQuestionnaireMapping: (postQuestion: any) => any;
  fetchActiveClassTeacher: (postQuestion: any) => any;
  getTeacherActiveNameList: () => any;
  fetchLicenseBySchoolGet: (getData: any) => any;
  getProfile: any;
  ListGrade: any;
  ListSection: any;
  AllClassList: any;
  AllStudentList: any;
  classTeacherData: any;
  records: any;
  ListAge: any;
  questionnaireitems: any;
  loading: any;
  classLoading: any;
  loadingStudent?: any;
  teacherNameList?: any;
  activeClassTeacher?: any;
  addQuestionnaireMapping?: any;
  license?: any;
  licenseBySchool?: any;
};
const initialValues = {
  select_age: "",
  is_parent_enable: false,
  is_otherteacher_enable: false,
  search_teacher_name: [],
};
interface StateTypes {
  academicYear?: any;
  modalShow: boolean;
  categoryQuestionList: any;
  selectedItems: any;
  selectedStudentList?: any,
  standardlist?: any,
  currentCheckboxAllStudent?: boolean,
  currentSelectedStandard?: any,
  isParentActive?: any,
  isClassTeacherActive?: any,
  isOtherTeacherActive?: any,
  addClassMapping?: any,
  selectedAge?: any,
  selectedClass?: any,
  filterTeacherIds?: any,
  searchAutoCompleteTeacher?: any,
  formIsSubmitting?: boolean,
  remainingLicense?: any,
  licenseBySchool?: any,
  modalAlertShow?: boolean,
  alertMessage?: string,
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export class AddPscyometric extends Component<OwnQuestSetProps, StateTypes> {
  formik: any;
  getStudentList: any;
  postStudentList: any = [];
  getEditClassList: any = [];
  constructor(props: any) {
    super(props);
    this.state = {
      academicYear: '',
      modalShow: false,
      categoryQuestionList: [],
      selectedItems: [],
      selectedStudentList: [],
      standardlist: [],
      currentCheckboxAllStudent: false,
      currentSelectedStandard: [],
      isParentActive: false,
      isClassTeacherActive: false,
      isOtherTeacherActive: false,
      addClassMapping: [],
      selectedAge: null,
      selectedClass: null,
      filterTeacherIds: [],
      searchAutoCompleteTeacher: [],
      formIsSubmitting: false,
      remainingLicense: 0,
      licenseBySchool: {},
      modalAlertShow:false,
      alertMessage:''
    };
    this.handleCheckStudent = this.handleCheckStudent.bind(this);
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getCurrectYear();
  }

  // This is life cycle is initial load  component Will Update
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.getCurrectYear(this.props.getChangeYearData);
    }
  }
  // This is life cycle is initial load  component Did Update
  componentDidUpdate() {
    const { stopYearDate } = this.props;
    if (stopYearDate === true) {
      this.props.stopYearData();
    }
  }
  // This is function is used get correct year
  getCurrectYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.getPsychometricDetails(getYear)
      this.setState({ academicYear: getYear });
    } else {
      this.getPsychometricDetails(this.props.getYear)
      this.setState({ academicYear: this.props.getChangeYearData });
    }
  }

  getPsychometricDetails(getYear?: any) {

    const getLicenseBySchool = {
      academic_year: getYear,
      module_name:'psychometric_module'
    }
    this.props.fetchLicenseBySchoolGet(getLicenseBySchool).then((res: any) => {
      const { licenseBySchool } = this.props
      const psychometricLicense =  licenseBySchool;
      this.setState({ licenseBySchool: psychometricLicense, remainingLicense: psychometricLicense.remaining_license });
    })

    const loadMoreType = {
      academic_year: getYear
    }

    this.props.fetchAgeGet();

    this.props.fetchGetAllClassList(loadMoreType).then((res: any) => {
      const { AllClassList } = this.props
      if (AllClassList.length > 0) {
        this.setState({ standardlist: AllClassList });
      }
    })

    const postValue: any = {
      page_no: 1,
      search: '',
      sort_by: '',
      order_by: '',
      role: 'Teacher',
      academic_year: getYear
    }
    this.props.fetchTeachersPost(postValue).then((res: any) => {
      window.scrollTo(0, 0);
    });

    this.props.getTeacherActiveNameList();

  }

  standardonSelect(e: any, data: any) {
    const { academicYear } = this.state;
    //const { AllStudentList } = this.props;
    const { item } = data;
    const { questionnaireitems } = this.props
    const sectionlistele: any = document.getElementById("sectionlist");
    if (sectionlistele.querySelector('.btn-info')) {
      sectionlistele.querySelector('.btn-info').classList.remove('btn-info');
    }
    this.setState({ currentSelectedStandard: item });
    e.target.classList.add('btn-info')
    const getStudent = {
      class_id: item.id,
      academic_year: academicYear,
      age_range: questionnaireitems.age_range
    }

    e.target.classList.add('active')

    const { selectedStudentList, isClassTeacherActive, remainingLicense } = this.state;
    this.props.fetchStudentListwithQuestionnaire(getStudent).then((res: any) => {
      this.unSelectAll();
      const findDiariesList = selectedStudentList.find((item: any) => item.id === getStudent.class_id);
      if (findDiariesList) {
        this.selectPreviousSelectedStudents(findDiariesList);
      }
      this.getRemainingLicense()
     
      const hasFalseOverallStatus = this.props.AllStudentList.some((item: any) => item.overall_status === false);
      const { classTeacherData } = this.props;
      if((isClassTeacherActive && classTeacherData && classTeacherData.length == 0)){
        this.activeDisableStudentCheckbox(true)
      }
    });
    //this.filterTeacherBySelectedStudent(item.id, isClassTeacherActive);

    
  }
  filterTeacherBySelectedStudent(id: any = null, isClassTeacherActive: any) {
    const { academicYear, selectedStudentList } = this.state;
    if (isClassTeacherActive && id) {
      const filteredData = selectedStudentList.filter((item: any) => item.studentslist.length > 0);
      const classIds = filteredData.map((item: any) => item.id);
      const updatedClassIdList = [...classIds, id];
      if (updatedClassIdList && updatedClassIdList.length > 0 && academicYear) {
        const getData = {
          class_id_list: updatedClassIdList,
          academic_year: academicYear.toString(),
        }
        this.props.fetchActiveClassTeacher(getData).then((res: any) => {
          const { activeClassTeacher } = this.props;
          this.setState({ filterTeacherIds: activeClassTeacher });

          const allTeacherLdapIds = activeClassTeacher.map((teacher: any) => teacher.teacher_id);
          const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
          this.formik.setFieldValue(`search_teacher_name`,filteredTeacher)
          this.setState({ searchAutoCompleteTeacher: filteredTeacher });

        });
      }
    }
    else if (isClassTeacherActive) {
      const filteredData = selectedStudentList.filter((item: any) => item.studentslist.length > 0);
      const classIds = filteredData.map((item: any) => item.id);
      if (classIds && classIds.length > 0 && academicYear) {
        const getData = {
          class_id_list: classIds,
          academic_year: academicYear.toString(),
        }
        this.props.fetchActiveClassTeacher(getData).then((res: any) => {
          const { activeClassTeacher } = this.props;
          this.setState({ filterTeacherIds: activeClassTeacher });
          const allTeacherLdapIds = activeClassTeacher.map((teacher: any) => teacher.teacher_id);
          const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
         this.formik.setFieldValue(`search_teacher_name`,filteredTeacher)
          this.setState({ searchAutoCompleteTeacher: filteredTeacher });

        });
      }else{

      const allTeacherLdapIds = this.props.activeClassTeacher.map((teacher: any) => teacher.teacher_id);
      const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
     this.formik.setFieldValue(`search_teacher_name`,filteredTeacher)
      this.setState({ searchAutoCompleteTeacher: filteredTeacher });
      }
    } else {
      this.setState({ filterTeacherIds: [] });
    }
  }

  filterTeacherBySelectStudent(){
    const { classTeacherData } = this.props;
    const { academicYear, selectedStudentList, isClassTeacherActive, currentSelectedStandard } = this.state;
    console.log(selectedStudentList)
    if(isClassTeacherActive){
      const gradeStandardsWithTeachers = selectedStudentList
      .filter((item:any) => item.class_teacher?.length > 0 && item.studentslist?.length > 0)
      //.filter((item:any) => item.studentslist.length !== 0)
      .map((item:any) => item.class_teacher[0]);

      const gradeStandardsWithTeachersNoStudent = selectedStudentList
      .filter((item:any) => item?.class_teacher?.length !== 0)
      .filter((item:any) => item.studentslist.length === 0)
      .map((item:any) => item?.class_teacher[0]);

      const teacherIds = gradeStandardsWithTeachersNoStudent.map((teacher: any) => teacher.teacher_id);

      const teacherwithIds = gradeStandardsWithTeachers.map((teacher: any) => teacher.teacher_id);
      console.log(teacherwithIds)
      const filtered = classTeacherData.filter(
        (teacher: any) => !teacherIds.includes(teacher.teacher_id) && teacherwithIds.includes(teacher.teacher_id)
      );
      const updatedClassIdList = [...gradeStandardsWithTeachers, ...filtered];  
         this.setState({ filterTeacherIds: updatedClassIdList });
         const allTeacherLdapIds = updatedClassIdList.map((teacher: any) => teacher.teacher_id);
         const filteredTeacher = this.state.searchAutoCompleteTeacher.filter((teacher: any) => !allTeacherLdapIds.includes(teacher.ldap_id));
         this.formik.setFieldValue(
          `search_teacher_name`,
          filteredTeacher
      )
         this.setState({ searchAutoCompleteTeacher: filteredTeacher });
    }
  }

  selectPreviousSelectedStudents(data: any) {
    const { AllStudentList } = this.props;
    const { all } = data;
    const selectAllCheckbox: any = document.getElementById('student_all');
    if (all) {

      AllStudentList.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })

      selectAllCheckbox['checked'] = true;
      this.setState({ currentCheckboxAllStudent: true })
    } else {

      data.studentslist.forEach((item: any, index: any) => {
        const checkbox: any = document.getElementById('student_' + item.id);
        checkbox['checked'] = true;
      })
      if (data.studentslist.length === AllStudentList.length) {
        const selectAllCheckbox: any = document.getElementById('student_all');
        selectAllCheckbox['checked'] = true;
      }
    }

  }

  getSectionList(e: any, getValue: any) {
    const value = getValue;
    const getGrade = {
      grade: value
    }

    const standardlistele: any = document.getElementById("standardlist");
    if (standardlistele.querySelector('.btn-pink')) {
      standardlistele.querySelector('.btn-pink').classList.remove('btn-pink');
    }
    e.target.classList.add('btn-pink')


    const sectionlistele: any = document.getElementById("sectionlist");
    if (sectionlistele.querySelector('.btn-info')) {
      sectionlistele.querySelector('.btn-info').classList.remove('btn-info');
    }

    this.setState({ currentSelectedStandard: [], selectedClass: value });

    this.props.fetchMySectionListGet(getGrade);
  }

  selectAllStudents() {
    const { AllStudentList } = this.props;
    const { currentSelectedStandard, selectedStudentList } = this.state;
    if (!this.state.currentCheckboxAllStudent) {
      if (AllStudentList.length === 0) {
        this.unSelectAll();
      }
      else {
        // Selected All
        this.SelectAll();

        const selectedAllStandardStudents: any = currentSelectedStandard;
        const trueOverallStatusIds = AllStudentList
          .filter((student: any) => !student.overall_status)
          .map((student: any) => student.id);
        if (trueOverallStatusIds && trueOverallStatusIds.length <= 0) {
          selectedAllStandardStudents['all'] = true;
          selectedAllStandardStudents['class_teacher'] = this.props.classTeacherData;
          selectedAllStandardStudents['studentslist'] = AllStudentList; // if you want to add all studentlist in add AllStudent array
        } else {
          selectedAllStandardStudents['class_teacher'] = this.props.classTeacherData;
          selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.overall_status);
        }
        const filteredPeople = selectedStudentList.filter((item: any) => item.id !== currentSelectedStandard.id);
        //this.setState({ selectedStudentList: [...filteredPeople, selectedAllStandardStudents] }, this.getRemainingLicense)
        this.setState({
          selectedStudentList: [...filteredPeople, selectedAllStandardStudents]
      }, () => {
        this.getRemainingLicense()
        this.filterTeacherBySelectStudent()
      });
      }
    } else {
      // unseleted all
      this.unSelectAll();
      const filteredPeople = selectedStudentList.filter((item: any) => item.id !== currentSelectedStandard.id);
     // this.setState({ selectedStudentList: filteredPeople }, this.getRemainingLicense)
      this.setState({
        selectedStudentList: filteredPeople
    }, () => {
      this.getRemainingLicense()
      this.filterTeacherBySelectStudent()
    });

    }
    //this.getRemainingLicense();
  }

  getRemainingLicense() {
    const { selectedStudentList,currentSelectedStandard, licenseBySchool } = this.state;
    const { license } = this.props;
    const totalStudentsCount = selectedStudentList.reduce((total: any, item: any) => total + item.studentslist.length, 0);
    const remainingLicense = licenseBySchool.remaining_license - totalStudentsCount;
    if (remainingLicense > 0) {
      const allCheckboxes = document.querySelectorAll('ul.autoselection li.list-group-item input[type="checkbox"][data-disabled="false"]');
      allCheckboxes.forEach((item: any, index: any) => {
          item['disabled'] = false;
      })

      this.setState({ remainingLicense: remainingLicense })
    } else {
      const allCheckboxes = document.querySelectorAll('ul.autoselection li.list-group-item input[type="checkbox"][data-disabled="false"]');
      allCheckboxes.forEach((item: any, index: any) => {
        if (!item.checked) {
          item['disabled'] = true;
        }else{
          item['disabled'] = false;
        }
       
      })
      
      this.setState({ remainingLicense: remainingLicense })
    }

   
 

    const item = selectedStudentList.find((item: any) => item.id === currentSelectedStandard?.id);
    const  currentSelectedStandardStudentslist = item ? item.studentslist.length : 0;
    const { AllStudentList } = this.props;
      let selectAllCheckbox: any = document.getElementById('student_all');
      
      if(selectAllCheckbox){
      if (remainingLicense < (AllStudentList.length - currentSelectedStandardStudentslist)) {
        selectAllCheckbox['disabled'] = true;
      }else{
        selectAllCheckbox['disabled'] = false;
      }}
  }

  activeDisableStudentCheckbox(value?:any){
    const allCheckboxes = document.querySelectorAll('ul.autoselection li.list-group-item input[type="checkbox"][data-disabled="false"]');
      allCheckboxes.forEach((item: any, index: any) => {
          item['disabled'] = value;
      })
      let selectAllCheckbox: any = document.getElementById('student_all');
      if(selectAllCheckbox){
        selectAllCheckbox['disabled'] = value;
      }
  }

  unSelectAll() {
    // unseleted all
    const { AllStudentList } = this.props;
    let selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      const checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = false;
    })
    this.setState({ currentCheckboxAllStudent: false })
    if (selectAllCheckbox) {
      selectAllCheckbox['checked'] = false;
    }

  }

  SelectAll() {
    const { AllStudentList } = this.props;
    const selectAllCheckbox: any = document.getElementById('student_all');
    AllStudentList.forEach((item: any, index: any) => {
      let checkbox: any = document.getElementById('student_' + item.id);
      checkbox['checked'] = true;
    })
    selectAllCheckbox['checked'] = true;
    this.setState({ currentCheckboxAllStudent: true })
  }

  getEditClassMappingList(getValue?: any, getData?: any, resonse?: any) {
    this.getEditClassList = [];
    const teacherList: any = [];
    if (getData) {
      getData.forEach((items: any) => {
        const getClass = String(items.ldap_id);
        this.getEditClassList.push(getClass)
        teacherList.push(items)
      })
      this.setState({ addClassMapping: this.getEditClassList, searchAutoCompleteTeacher: teacherList })
    }
  }

  handleCheckStudent(e: any) {

    const { selectedStudentList, currentSelectedStandard } = this.state;
    const { classTeacherData } = this.props;
    //if(classTeacherData && classTeacherData.length !== 0){
    const { AllStudentList } = this.props;
    const standardId: any = currentSelectedStandard;
    const checkbox: any = document.getElementById('student_' + e.currentTarget.dataset.id);
    if (checkbox.checked) {
      checkbox['checked'] = true;
      const availableDiareslist = selectedStudentList.filter((item: any) => item.id === standardId.id);
      if (availableDiareslist.length) {
        const findDiariesList = selectedStudentList.map((item: any) => {
          if (item.id === standardId.id) {
            item.studentslist.push(AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id))[0])
            if (item.studentslist.length === AllStudentList.length) {
              const selectAllCheckbox: any = document.getElementById('student_all');
              selectAllCheckbox['checked'] = true;
              item.all = true;
              checkbox['checked'] = true;
            }
          }
          return item;
        });
        //this.setState({ selectedStudentList: findDiariesList }, this.getRemainingLicense);
        this.setState({
          selectedStudentList: findDiariesList
      }, () => {
        this.getRemainingLicense()
        this.filterTeacherBySelectStudent()
      });
      }
      else {
        const selectedAllStandardStudents: any = currentSelectedStandard;
        selectedAllStandardStudents['all'] = false;
        selectedAllStandardStudents['studentslist'] = AllStudentList.filter((item: any) => item.id === parseInt(e.currentTarget.dataset.id));
        if(AllStudentList.length == 1){
          selectedAllStandardStudents['all'] = true;
          const selectAllCheckbox: any = document.getElementById('student_all');
          selectAllCheckbox['checked'] = true;
        }
        selectedAllStandardStudents['class_teacher'] = classTeacherData;
       // this.setState({ selectedStudentList: [...selectedStudentList, selectedAllStandardStudents] }, this.getRemainingLicense)
        this.setState({
          selectedStudentList: [...selectedStudentList, selectedAllStandardStudents]
      }, () => {
        this.getRemainingLicense()
        this.filterTeacherBySelectStudent()
      });
      }
     // this.filterTeacherBySelectStudent()
    } else {
      const findDiariesList = selectedStudentList.map((item: any) => {
        if (item.id === standardId.id) {
          let studentslist;
          if (item.all) {
            studentslist = AllStudentList.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          } else {
            studentslist = item.studentslist.filter((item: any) => item.id !== parseInt(e.currentTarget.dataset.id))
          }
          item.studentslist = studentslist;
          item.all = false;
        }
        return item;
      })
     // this.setState({ selectedStudentList: findDiariesList }, this.getRemainingLicense);
     this.setState({
      selectedStudentList: findDiariesList
  }, () => {
    this.getRemainingLicense()
    this.filterTeacherBySelectStudent()
  });

      this.setState({ currentCheckboxAllStudent: false });
      const selectAllCheckbox: any = document.getElementById('student_all');
      selectAllCheckbox['checked'] = false;
      checkbox['checked'] = false;
    }
    //this.getRemainingLicense();
 // }
  }

  isParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isParentActive: event.target.checked });
  }

  isClassTeacherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   if(event.target.checked){
    const { selectedStudentList } = this.state;
    const { classTeacherData } = this.props;
    const gradeStandardsWithoutTeachers = selectedStudentList
      .filter((item:any) => item.class_teacher?.length === 0)
      .filter((item:any) => item.studentslist.length !== 0)
      .map((item:any) => item.grade_standard);

      if(gradeStandardsWithoutTeachers.length > 0) {
        const message = `No class teacher following classes ${gradeStandardsWithoutTeachers.join(', ')}`;
        this.setState({ modalAlertShow: true,alertMessage:message });
      }
      else{
    this.setState({ isClassTeacherActive: event.target.checked });
        this.filterTeacherBySelectedStudent('', event.target.checked);
        if((classTeacherData && classTeacherData.length == 0)){
          this.activeDisableStudentCheckbox(true)
        }
      }
    }else{
      this.setState({ isClassTeacherActive: event.target.checked });
      this.filterTeacherBySelectedStudent('', event.target.checked);
      this.activeDisableStudentCheckbox(false)
    }
  }

  isOtherTeacherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isOtherTeacherActive: event.target.checked });

  }

  onCancel = (getValue: any) => {
    this.setState({ modalShow: false, modalAlertShow: false});
  }

  openModel = () => {
    this.setState({ modalShow: true });
  }

  validate = (values: any) => {
    const errors = {};
    if (!values.search_teacher_name) {
      //errors.search_teacher_name = 'This field is required';
    }
    return errors;
  }

  handleToggleItem = (item: any) => {
    const { selectedItems } = this.state
    const updatedItems = selectedItems.includes(item)
      ? selectedItems.filter((selectedItem: any) => selectedItem !== item)
      : [...selectedItems, item];

    this.setState({ selectedItems: updatedItems });
  }

  getPostQuestionnaireitems = (e: any, getValue?: any) => {
    const loadMoreType = {
      id: getValue
    }
    if (getValue) {
      this.props.getQuestionnaireByAge(loadMoreType).then((res: any) => {
        const { questionnaireitems } = this.props
        this.setState({ categoryQuestionList: questionnaireitems.question_details, selectedAge: getValue });

      })
    }
  };

  render() {
    const { getProfile, ListAge, questionnaireitems, loading, classLoading, loadingStudent, teacherNameList, license } = this.props;
    const spinnerLoading = { display: loading || classLoading || loadingStudent ? "block" : "none" };
    const {
      modalShow,
      categoryQuestionList,
      selectedItems,
      selectedStudentList,
      standardlist,
      isParentActive,
      isClassTeacherActive,
      isOtherTeacherActive,
      currentSelectedStandard,
      selectedAge,
      selectedClass,
      filterTeacherIds,
      formIsSubmitting,
      remainingLicense,
      modalAlertShow,
      alertMessage
    } = this.state;

    let gradeList: any;
    if (this.props.ListGrade) {
      gradeList = this.props.ListGrade;
    }

    let age: any;
    if (this.props.ListAge) {
      age = this.props.ListAge;
    }

    let teacherList: any;
    teacherList = teacherNameList;

    const teacherfilteredData = teacherList.filter((student: any) => !filterTeacherIds.some((teacher: any) => teacher.teacher_id === student.ldap_id));

    let getStudentList: any;
    if (this.props.AllStudentList) {
      getStudentList = this.props.AllStudentList;
    }
    return (
      <div>
        <ModalView
          onState={modalShow}
          onCancel={this.onCancel}
          categoryQuestionList={categoryQuestionList}
          handleToggleItem={this.handleToggleItem}
          selectedItems={selectedItems}
        />
        <ModalAlert
          onState={modalAlertShow}
          onCancel={this.onCancel}
          message={alertMessage}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={["Assign Psychometric Paper"]}
                homeName={["Home"]}
                url={["dashboard"]}
                mainPageTitle={["Assign Psychometric Paper"]}
                baseName={["Psychometric"]}
                baseURL={["psychometric"]}
              />
              <div className="row">
                <div className="col-md-12">
                  <Formik
                    ref={node => this.formik = node}
                    initialValues={initialValues}
                    validationSchema={schema}
                    validate={this.validate}
                    onSubmit={(values, { setSubmitting }) => {
                      this.setState({ formIsSubmitting: true })
                      let getQuestionValue: any = [];
                      let submitValue: any = [];
                      this.postStudentList = []
                      if (values && (isParentActive || isClassTeacherActive || isOtherTeacherActive)) {
                        let studentList: any;
                        selectedStudentList.forEach((items: any, index: any) => {
                          items.studentslist.forEach((students: any) => {
                            studentList = {
                              student_id: students.id,
                              class_id: items.id,
                              user_id: students.user_id
                            }
                            this.postStudentList.push(studentList)
                          })
                        })
                        const otherTeacherIds = this.state.searchAutoCompleteTeacher.map((teacher:any) => teacher.ldap_id);
                        // const createrBy: any = `${getProfile.firstname} ${getProfile.lastname}`;
                        getQuestionValue = {
                          school_id: getProfile.school_id,
                          class_student_details: this.postStudentList,
                          is_class_teacher: this.state.isClassTeacherActive,
                          is_parent: this.state.isParentActive,
                          additional_teacher_ids: this.state.isOtherTeacherActive ? otherTeacherIds : [],
                          academic_year: this.state.academicYear,
                          name: questionnaireitems.name,
                          description: questionnaireitems.description,
                          age_range: questionnaireitems.age_range,
                          question_details: categoryQuestionList,
                        };
                        this.props.CreateQuestionnaireMapping(getQuestionValue).then((res: any) => {
                          const { addQuestionnaireMapping } = this.props
                          if (addQuestionnaireMapping.status == true && !addQuestionnaireMapping?.error_list) {
                            history.push("/psychometric");
                          }
                          else if (addQuestionnaireMapping?.error_list && addQuestionnaireMapping.error_list.length <= 0) {
                            // history.push("/psychometric");
                          }
                          //
                        });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      isSubmitting,
                      isValidating,
                      dirty,
                      touched,
                      handleReset,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">

                            <div className="col-md-6">

                              <div className="col-md-12">
                                <FormGroup className="w-100 mb-4">
                                  {
                                    age && age.length > 0 ? (
                                      <Field
                                        label={<><span>Select Age</span><span className='formmandatorycolor'> *</span></>}
                                        name="select_age"
                                        select
                                        component={TextField}
                                        disabled={false}
                                        onChange={(e: any, value: any) => {
                                          setFieldValue(
                                            "select_age",
                                            e.target.value
                                          );
                                          this.getPostQuestionnaireitems(e, e.target.value);
                                        }}
                                      >
                                        {age && age.length > 0 && age.map((item: any) => (
                                          <MenuItem value={item.id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    ) : null}
                                </FormGroup>
                              </div>
                              {questionnaireitems?.name ?
                                (<div className="col-md-12">
                                  <div className="docListWindow small-slimscroll-style autoselection" style={{ padding: '0', margin: '0' }}>
                                    <div className="title-sticky zindex0 list-group-item">
                                      <label><strong>Name :</strong> {selectedAge ? questionnaireitems.name : ""}</label>
                                      <label><strong>Description : </strong>{selectedAge ? questionnaireitems.description : ""}</label>
                                      <Button className="btn btn-pink mr-1 ml-1" onClick={this.openModel} disabled={!selectedAge}>
                                        View Question
                                      </Button>
                                    </div>
                                  </div>
                                </div>) : null}

                              <div style={{ margin: "20px", marginTop: "20px" }}>Exam invigilator</div>
                              <div className="row col-md-12" style={{ marginTop: '20px' }}>

                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={isParentActive}
                                    onChange={
                                      (e) => {
                                        this.isParentChange(e)
                                      }
                                    }

                                    name="is_parent_enable"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title={isParentActive ? "is parent Active" : "is parent disable"}
                                  />}
                                  className="col-md-3"
                                  label="Is parent"
                                  labelPlacement="end"
                                />

                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={isClassTeacherActive}
                                    onChange={this.isClassTeacherChange}
                                    name="is_classteacher_enable"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title="Activate Student"
                                  />}
                                  className="col-md-4"
                                  label="Is Class teacher"
                                  labelPlacement="end"
                                />

                                <FormControlLabel
                                  value="end"
                                  control={<Switch
                                    checked={isOtherTeacherActive}
                                    onChange={(e) => {
                                      setFieldValue('is_otherteacher_enable', e.target.checked)
                                      this.isOtherTeacherChange(e)
                                    }}
                                    name="is_otherteacher_enable"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    title="Activate Student"
                                  />}
                                  className="col-md-4"
                                  label="Other teacher"
                                  labelPlacement="end"
                                />

                                {isOtherTeacherActive && teacherfilteredData ? <div className="col-md-12">
                                  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0 pb-0">
                                    <FormGroup>
                                      <Autocomplete
                                        fullWidth
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={teacherfilteredData}
                                        disableCloseOnSelect
                                        noOptionsText={'No matching records found.'}
                                        getOptionLabel={(option: any) => option.firstname}
                                        value={this.state.searchAutoCompleteTeacher}
                                        onChange={(e, value, resonse) => {
                                          setFieldValue('search_teacher_name', value);
                                          this.getEditClassMappingList(e, value, resonse)
                                        }}
                                        renderOption={(option, { selected }) => {
                                          return (
                                            <React.Fragment>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option.firstname}
                                            </React.Fragment>
                                          )
                                        }}
                                        className="mb-0 mt-1"
                                        renderInput={(params) => (
                                          <Field
                                            component={TextField}
                                            name="search_teacher_name"
                                            error={!!errors.search_teacher_name}
                                            helperText={errors.search_teacher_name}
                                            {...params} label={<><span>Search Teacher Name</span><span className='formmandatorycolor'> *</span></>}
                                          />
                                        )}
                                      />

                                    </FormGroup>
                                  </div>
                                </div>
                                  : null}

                                <>
                                  {formIsSubmitting && !isParentActive && !isClassTeacherActive && !isOtherTeacherActive ? (
                                    <div className="col-md-12 text-center mt-3">

                                      <div className="errorcolor">
                                        Please Select any of the Exam Invigilator
                                      </div>

                                    </div>
                                  ) : null}
                                </>

                              </div>
                              {license &&
                                <div className="col-md-12" style={{ marginTop: '20px' }}>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="overview-panel purple
                                            text-center mt-2 mb-1">
                                        <div className="value white">
                                          <p className="mb-1 mt-1"> Total License :</p>
                                          <h5 className="mt-1 color-pink">
                                            {remainingLicense}</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>}

                            </div>

                            <div className="col-md-6">
                              <div style={{ margin: "10px", marginTop: "20px", marginBottom: "10px" }}>Select Class</div>
                              <div className="row col-md-12">
                                {/* List the class details list */}
                                <div id="standardlist" className="col-md-12" style={{ marginTop: '10px', marginBottom: '10px', padding: '0' }}>
                                  {gradeList ?
                                    <>
                                      {gradeList.map((item: any, index: any) => (
                                        <span className={`btn btn-circle ${!selectedAge && 'disable'}`} style={{ marginLeft: '10px' }} key={index} onClick={(e) => selectedAge && this.getSectionList(e, item.grade_id)}>{item.value}</span>
                                      ))}
                                    </>
                                    : <CommonLoader />}
                                </div>

                                <div id="sectionlist" className="col-md-12" style={{ marginTop: '20px', marginBottom: '20px', padding: '0' }}>
                                  {selectedClass && this.props.ListSection ?
                                    <>
                                      <div style={{ margin: "10px", marginTop: "0", marginBottom: "10px" }}>Select Section</div>
                                      {this.props.ListSection.map((item: any, index: any) => (
                                        <span className="btn btn-squre" style={{ marginLeft: '10px' }} key={index} onClick={(e) => this.standardonSelect(e, { item, index })}>{item.value}</span>
                                      ))}
                                    </>
                                    : <div className="m-3"> <h5>Please Select Class</h5></div>}
                                </div>

                                <div className="col-md-12" style={{ padding: '0', margin: '0' }}>

                                  <ul className="docListWindow small-slimscroll-style autoselection">
                                    {currentSelectedStandard?.id && getStudentList && getStudentList.length > 0 ?
                                      <>
                                        <li className="title-sticky list-group-item">
                                          <div className="form-check">
                                            <input type="checkbox" className="form-check-input" defaultChecked={this.state.currentCheckboxAllStudent} id={'student_all'} onClick={() => this.selectAllStudents()}/>
                                            <label className="form-check-label" htmlFor="exampleCheck1"><strong>Select All</strong></label>
                                          </div>
                                        </li>
                                        <PsychometricStudentList studentlist={getStudentList} handleCheckStudent={this.handleCheckStudent} />
                                      </>
                                      : currentSelectedStandard?.id ? <div className="col-md-12"> No Student Selected Class </div> : null}
                                  </ul>

                                </div>

                                {/* {this.getAllStudentDetails ? */}
                                <div className="col-12" style={{ marginTop: '20px', marginBottom: '10px' }}>
                                  {selectedStudentList ?
                                    <>
                                      {selectedStudentList.map((item: any, index: any) => (
                                        <>
                                          {item.all ?
                                            <span className="btn btn-circle btn-circle btn-info disabled mr-2 mb-3" key={index}>{item.grade_standard} </span>
                                            : <>{item.studentslist.length > 0 && item.studentslist !== undefined ?

                                              item.studentslist.map((slist: any, index: any) => (
                                                <span className="btn btn-circle btn-circle btn-danger disabled mr-2 mb-3" key={index}>
                                                  <span className="userNameTitle">{slist.student_name} ({item.grade_standard})</span></span>
                                              ))
                                              : null}</>
                                          }
                                        </>
                                      ))}
                                    </> : 'No Selected'}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-right mb-3 mr-2 mt-4">
                            <Button
                              className="btn btn-pink mr-1 ml-1"
                              type="submit"
                              disabled={
                                (this.state.selectedStudentList && this.state.selectedStudentList.length <= 0)}
                            >
                              Submit
                            </Button>
                            <Link to={"/psychometric"}>
                              <Button className="btn btn-default mr-1 ml-1 ">
                                Cancel
                              </Button>
                            </Link>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={spinnerLoading}><SpinnerLoader /></div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.psychometric.loading,
    classLoading: state.classes.loading,
    loadingStudent: state.diary.loading,
    getChangeYearData: state.profile.getYear,
    stopYearDate: state.profile.yearStatusFails,
    getProfile: state.profile.profileData,
    ListGrade: state.classes.my_class,
    ListSection: state.classes.my_section,
    AllClassList: state.diary.gradelist,
    AllStudentList: state.psychometric.student_list,
    classTeacherData: state.psychometric.class_teacher_data,
    getYear: state.profile.getYear,
    records: state.teacher.records,
    teacherNameList: state.teacher.GetTeacherActiveName,
    ListAge: state.psychometric.ageList,
    questionnaireitems: state.psychometric.questionnaireitems,
    activeClassTeacher: state.psychometric.activeClassTeacher,
    addQuestionnaireMapping: state.psychometric.addQuestionnaireMapping,
    license: state.license.license,
    licenseBySchool: state.license.licenseBySchool

  };
};

export default connect(mapStateToProps, {
  stopYearData,
  fetchGetAllClassList,
  fetchGetStudent,
  fetchTeachersPost,
  fetchMyClassListGet,
  fetchMySectionListGet,
  fetchAgeGet,
  getQuestionnaireByAge,
  CreateQuestionnaireMapping,
  getTeacherActiveNameList,
  fetchActiveClassTeacher,
  fetchStudentListwithQuestionnaire,
  fetchLicenseBySchoolGet
})(AddPscyometric);
