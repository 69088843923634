import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { fetchTeacherPerformance } from '../../../store/dashboard/Actions';
import { CommonState } from '../../../components/type';
import { fetchGetAllClassListTeacherperformance } from '../../../store/diary/Actions';
import CommonLoader from '../../../components/CommonLoader';
import history from '../../../History';
import Cookies from 'universal-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { stopYearData, stopSubComponentYear } from '../../../store/profile/Actions'

export type PostsTeacherProps = {
	stopYearDate?:any
	subComponentStatus?:any
	getChangeYearData?:any;
	fetchGetAllClassListTeacherperformance: (postPage: any) => any;
	fetchTeacherPerformance: (postValue: any) => any;
	stopYearData:()=>any;
	stopSubComponentYear:()=>any
	loading?:any;
	loadingDashboard?:any;
	getAllClassList?:any;
	getProfile?:any;
	getTeacherPerformance?:any;
	getSchoolId?:any;
	TotalTeacherPerformance?:any;
	PerPageTeacherPerformance?:any;
	PageTeacherPerformance?:any;
	isTeacherPerformanceLoader?:boolean;
	isSubjectTeacherPerformanceLoader?:boolean
	
}
export class TeacherPerformanceView extends Component<PostsTeacherProps, CommonState> {
    cookies:any = new Cookies();
	constructor(props: any) {
		super(props);
		this.state = {
			page:1,
			page_no: 1,
			search: '',
            hasMore: true,
			academicYear:null,
			class_id:null,
			messageData:'',
			TeacherEngagement:[],
			TeacherPerformance:[],
			hasMoreTeacherPerformance:true
		}
	}
    componentDidMount(): void {
		window.scrollTo(0, 0);
		this.getCurrectYear()
	}
	componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate, subComponentStatus} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}else if(subComponentStatus === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}else {
			
		}
	}
	componentDidUpdate() {
		const {stopYearDate, subComponentStatus} = this.props;
		if(subComponentStatus === true){
			this.props.stopSubComponentYear()
		}else if(stopYearDate === true){
			this.props.stopYearData()
		}
	}
	getCurrectYear(getYear?:any){
		this.setState({show:false})
		if(getYear && getYear !== undefined && getYear !== null){
			this.viewUserTypes(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.viewUserTypes(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
	viewUserTypes(getYear?:any){
		const { getProfile } = this.props;
		if(getProfile && getProfile !== undefined && getProfile !== null){
			if(getProfile.usertype === 1){
				this.getAcademicTeacherDetails(getYear);
			} else {
				this.commonTeacherDetails(getYear)
			}
		}
	}

	commonTeacherDetails(getYear?:any){
		if(getYear){
			let	loadMoreType:any = {
				academic_year: getYear
			  }
				this.props.fetchGetAllClassListTeacherperformance(loadMoreType).then((res: any) => {
					const { getAllClassList } = this.props;
					if(getAllClassList !== undefined && getAllClassList !== null && getAllClassList.length > 0){
								const getPerformanace:any = {
									academic_year: getYear,
									class_id: String(getAllClassList[0].id),
									page_no: 1
								  }
								  this.setState({class_id:String(getAllClassList[0].id)})
								this.props.fetchTeacherPerformance(getPerformanace).then((res:any)=>{
									const { getTeacherPerformance } = this.props;
									if(getTeacherPerformance && getTeacherPerformance !== undefined && getTeacherPerformance !== null){
										if(getTeacherPerformance.length > 0){
											this.setState({TeacherPerformance:getTeacherPerformance})
										}else {
											this.setState({TeacherPerformance:[]})
										}
									}
								});	
					}
				  })
		}
	}
//  Developer Name: Sudhakar.k
//  This is function used to academic admin teacher performance role
	getAcademicTeacherDetails(getYear?:any){
		const { getSchoolId } = this.props;
		if(getYear){
			let	loadMoreType:any = {
				academic_year: getYear,
				school_id:getSchoolId
			  }
				this.props.fetchGetAllClassListTeacherperformance(loadMoreType).then((res: any) => {
					const { getAllClassList, getSchoolId } = this.props;
					if(getAllClassList.length && getSchoolId){
							const getPerformanace:any = {
								academic_year: getYear,
								class_id: String(getAllClassList[0].id),
								page_no: 1,
								school_id:getSchoolId
							  }
							  this.setState({class_id: String(getAllClassList[0].id)})
							this.props.fetchTeacherPerformance(getPerformanace).then((res:any)=>{
								const { getTeacherPerformance } = this.props;
								if(getTeacherPerformance && getTeacherPerformance !== undefined && getTeacherPerformance !== null){
									if(getTeacherPerformance.length > 0){
										this.setState({TeacherPerformance:getTeacherPerformance})
									}else {
										this.setState({TeacherPerformance:[]})
									}
								}
							});
					}
				  })
		}
	}

	getGradeValue = (e:any) =>{
		const { getSchoolId, getProfile } = this.props;
		const {academicYear} = this.state;
		const { value } = e.target;
		let getPerformanace:any
		if(value){
			if(getProfile.usertype === 1){
				getPerformanace = {
					academic_year: academicYear,
					class_id: value,
					page_no: 1,
					school_id:getSchoolId
				}
				this.setState({class_id:value})
				this.props.fetchTeacherPerformance(getPerformanace).then((res:any)=>{
					const { getTeacherPerformance } = this.props;
					if(getTeacherPerformance && getTeacherPerformance !== undefined && getTeacherPerformance !== null){
						if(getTeacherPerformance.length > 0){
							this.setState({TeacherPerformance:getTeacherPerformance})
						}else {
							this.setState({TeacherPerformance:[]})
						}
					}
				});	;
			} else if(getProfile.usertype === 2 || getProfile.usertype === 3) {
				getPerformanace = {
					academic_year: academicYear,
					class_id: value,
					page_no: 1
				}
				this.setState({class_id:value})
				this.props.fetchTeacherPerformance(getPerformanace).then((res:any)=>{
					const { getTeacherPerformance } = this.props;
					if(getTeacherPerformance && getTeacherPerformance !== undefined && getTeacherPerformance !== null){
						if(getTeacherPerformance.length > 0){
							this.setState({TeacherPerformance:getTeacherPerformance})
						}else {
							this.setState({TeacherPerformance:[]})
						}
					}
				});
			}		
		}
	}

	  public fetchTeacherPerformance = () => {
		  const {academicYear} = this.state;
        if (this.state.hasMoreTeacherPerformance === true) {
         if (Math.ceil(this.props.TotalTeacherPerformance / this.props.PerPageTeacherPerformance) > this.props.PageTeacherPerformance) {   
            const postValue:any = {
              page_no: this.state.page_no + 1,
              academic_year: academicYear
            }              
            this.props.fetchTeacherPerformance(postValue);
            this.setState({
                page: this.state.page + 1
            }) 
          }
    
          if (Math.ceil(this.props.TotalTeacherPerformance / this.props.PerPageTeacherPerformance) === this.props.PageTeacherPerformance) {
            this.setState({
				hasMoreTeacherPerformance: false,
            })
          }
        }
      }
	postTeacherDetails= (getValue:any) =>{
		const { getSchoolId } = this.props;		
        this.cookies.set('ACADEMICSCHOOLID', getSchoolId)
		history.push({
			pathname: `/teachers/${getValue.teacher_id}`,
		  });
		  localStorage.setItem('TeacherDetails', getValue.teacher_name)
	}
	addDefaultSrc= (ev:any) =>{
        ev.target.src = '../assets/img/user/teacher-profile.jpg'
      }
	  refreshTeacherPerformance = () => {
        const {academicYear} = this.state;
		const { getProfile } = this.props;
		if(getProfile.usertype === 1){
			this.getAcademicTeacherDetails(academicYear);
		} else {
			this.commonTeacherDetails(academicYear)
		}
	}
   
    render() {
        const { getAllClassList, isSubjectTeacherPerformanceLoader,isTeacherPerformanceLoader } = this.props;
        const { TeacherPerformance } = this.state;
		const isPerformanceLoaderVisible = { display: isSubjectTeacherPerformanceLoader ? "block" : isTeacherPerformanceLoader ? "block" :"none" };
        return (
            <div>
                	<div className="card card-topline-red">
							<div className="card-head">
							<div className="overlay" style={isPerformanceLoaderVisible}><div className='custom-spinner-individual'></div></div>
								<header className="mt-3">Teacher Performance</header>
								<div className="tools">
								<div className="d-flex">
									<div>
									{getAllClassList ?
									<select name="className pb-0" id="" className="form-control" onChange={this.getGradeValue}>
									{getAllClassList.map((items:any)=>(
										<option value={items.id}> {items.grade_standard} </option>
									))}
									</select>
									:null}
									</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh" onClick={this.refreshTeacherPerformance}></span>
											<span className="t-collapse btn-color fa fa-chevron-up"></span>
										</div>
									</div>	
								</div>
							</div>
							<div className="card-body ">
								<InfiniteScroll
                                dataLength={TeacherPerformance.length}
                                next={this.fetchTeacherPerformance}
                                hasMore={this.state.hasMoreTeacherPerformance}
                                loader={<h4 style={isPerformanceLoaderVisible}>Loading...</h4>}
                                >
									<ul className="docListWindow small-slimscroll-style overflowBox width100">
										<li className="title-sticky" style={{zIndex:2}}>
											<div className="row">

												<div className="col-md-6"><strong>Teacher name (Subject)</strong></div>
												<div className="col-md-3 text-center"><strong>Total Responses</strong></div>
												<div className="col-md-3 text-center"><strong>Performance</strong></div>
											</div>
										</li>
										{TeacherPerformance.length > 0 ?
										<>
										{TeacherPerformance.map((items:any)=>(
											<li>
											<div className="row">
												<div className="col-md-6 col-sm-6">
												{ items.teacher_profile?
                                                         <div>
                                                         <div className="profileImage">
                                                        <img onError={this.addDefaultSrc} src={`${process.env.REACT_APP_API_URL}${items.teacher_profile}`} 
                                                        alt="Student Profile" width="40"
                                                        height="40"/>
                                                                    </div>
                                                     </div>
                                                        :
														<div className="prog-avatar">
															<button
																className="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ml-3 btn-pink">
																<span>{items.teacher_name.charAt(0)}</span>											</button>
														</div>
                                                        }
													<div className="details">
														<div className="title">
															<Link to={"#"} onClick={()=> this.postTeacherDetails(items)}>{items.teacher_name}</Link>
														</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-3 rating-style">
													<p className='text-center'>{items.total_responses}</p>
												</div>
												<div className="col-md-3 col-sm-3 rating-style">
													<p className='text-center'>{items.total_performance}</p>
												</div>
											</div>
										</li>
										))}
										</>
										:<CommonLoader/>}
									</ul>
                                    </InfiniteScroll>
							</div>
						</div>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => ({
    loading:state.profile.loading,
    loadingDashboard:state.dashboard.loading,
	getChangeYearData:state.profile.getYear,
	stopYearDate:state.profile.yearStatusFails,
	subComponentStatus:state.profile.subComponentStart,
    getAllClassList:state.diary.gradelist,
    getProfile:state.profile.profileData,
    getTeacherPerformance:state.dashboard.records_Teacher_Performance,
    TotalTeacherPerformance:state.dashboard.total_Teacher_Performance,
    PerPageTeacherPerformance:state.dashboard.per_page_Teacher_Performance,
    PageTeacherPerformance:state.dashboard.page_Teacher_Performance,
	isTeacherPerformanceLoader:state.dashboard.isTeacherPerformanceLoader,
	isSubjectTeacherPerformanceLoader:state.diary.isSubjectTeacherPerformanceLoader
})


export default connect(mapStateToProps, {fetchTeacherPerformance, fetchGetAllClassListTeacherperformance, stopYearData, stopSubComponentYear})(TeacherPerformanceView)
