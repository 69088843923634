import * as React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import ViewSubjectDetails from '../modules/subject/ViewSubjectDetails'
import EditSubject from '../modules/subject/EditSubject'
import AddNewSubject from '../modules/subject/AddNewSubject'
import ViewSchoolDetails from '../modules/school/ViewSchoolDetails'
import DashboardIndexPage from '../modules/dashboard/Index'
import ForgotPassword from '../modules/authentication/ForgotPassword'
import ResetPassword from '../modules/authentication/ResetPassword'
import AddNewSchool from '../modules/school/AddNewSchool'
import EditSchool from '../modules/school/EditSchool'
import history from '../History';
import {
  AuthRoutes, NonAuthRoutes,
  SchoolManage, UserManagementUrl, StudentDetails,
  TeacherDetails, DiaryList, NoticeBoard, Question, ClassesMenu, commonmenu, FeedBackMenu, ARTagDownload, HelpDownload,DemoDel, Pscyometric, QuestionBank, LicensePartitionMenu,
  StudentPromotion,
  ReportView
} from './Roles';
import { UserRoles } from '../services/Constants'
import AuthRoute from './AuthRoute'
import BaseLayout from '../layout/BaseLayout';
import UnAuthorized from '../modules/errorhandlers/unautheration';
import UserLogin from '../modules/authentication/UserLogin';
import UserLoginPassword from '../modules/authentication/UserLoginPassword';
import UserRegister from '../modules/authentication/UserRegister';
import ViewClassesDetails from '../modules/classes/ViewClassesDetails';
import AddNewClasses from '../modules/classes/AddNewClasses';
import EditClasses from '../modules/classes/EditClasses';
import SchoolAdminProfile from '../modules/school/SchoolAdminProfile';
import UserManagement from '../modules/usermanagement/UserManagement';
import AddUserManage from '../modules/usermanagement/AddUserManage';
import EditUserManage from '../modules/usermanagement/EditUserManage';
import UserRegisterPassword from '../modules/authentication/UserRegisterPassword';
import OtpValidation from '../modules/authentication/OtpValidation';
import ViewStudentDetails from '../modules/student/ViewStudentDetails';
import AddStudent from '../modules/student/AddStudent';
import EditStudent from '../modules/student/EditStudent';
import TeacherView from '../modules/teacher/TeacherView';
import ViewFullDetailTeacher from '../modules/teacher/ViewFullDetailTeacher';
import AddSubject from '../modules/teacher/AddSubject';
import AddTeacher from '../modules/teacher/AddTeacher';
import ViewStudentFullDetails from '../modules/student/ViewStudentFullDetails';
import AddClassInCharge from '../modules/teacher/AddClassInCharge';
import EditTeacher from '../modules/teacher/EditTeacher';
import ViewDiaryDetails from '../modules/diary/ViewDiaryDetails';
import ViewNoticeBoard from '../modules/noticeboard/ViewNoticeBoard';
import ForgotOTPCheck from '../modules/authentication/ForgotOTPCheck';
import NotFoundPage from '../modules/errorhandlers/NotFoundPage';
import ViewQuestion from '../modules/questionset/ViewQuestion';
import AddQuestion from '../modules/questionset/AddQuestion';
import ViewQuestionDetails from '../modules/questionset/viewQuestionDetails';
import EditClassInCharge from '../modules/teacher/EditClassInCharge';
import EditTeacherSubject from '../modules/teacher/EditTeacherSubject';
import Index from '../modules/teacherEngagement/Index';
import classes from '../modules/classesgroup/classes';
import InstantFeedback from '../modules/InstantFeedback/InstantFeedback';
import ViewInstantFeedback from '../modules/InstantFeedback/ViewInstantFeedback';
import Attendance from '../modules/attendance/Attendance';
import ViewStudentFull from '../modules/student/studentfullDetails/ViewStudentFull';
import QuizzesReport from '../modules/report/QuizzesReport';
import Organizations from '../modules/Organizations/Organizations';
import ViewSchoolDetailsTeam from '../modules/dashboard/ViewSchoolDetails';
import Quizzes from '../modules/quizzes/Quizzes';
import FeedBack from '../modules/feedback/FeedBack';
import QuizzesReportView from '../modules/quizzes/QuizzesReport';
import StudentQuestionView from '../modules/quizzes/StudentQuestionView';
import TeacherAttendance from '../modules/teacher/TeacherAttendance';
import ArTagDownload from '../modules/ARTag/ArTagDownload';
import HelpguideDownload from '../modules/HelpDownload/help_guide_download';
import DemoDelete from '../modules/DemoDel/demo_delete';
import SingleQuizzesView from '../modules/quizzes/SingleQuizzesView';
import UserProfile from '../modules/profile/UserProfile'
import UploadQuestion from '../modules/questionset/UploadQuestion';
import ViewPscyometric from '../modules/pscyometric/ViewPscyometric';
import AddPscyometric from '../modules/pscyometric/AddPscyometric';
import EditPscyometric from '../modules/pscyometric/EditPscyometric';
import StudentListPsychometricReport from '../modules/pscyometric/StudentListPsychometricReport';
import StudentPsychometricReport from '../modules/pscyometric/StudentPsychometricReport';
import BluePrintGrid from '../modules/QuestionBank/BluePrintGrid';
import AddBluePrint from '../modules/QuestionBank/AddBluePrint';
import GenerateQuestions from '../modules/QuestionBank/GenerateQuestions';
import QuestionBankGrid from '../modules/QuestionBank/QuestionBankGrid';
import ViewQuestionPaper from '../modules/QuestionBank/ViewQuestionPaper';
import EditBluePrint from '../modules/QuestionBank/EditBluePrint';
import LicensePartition from '../modules/licensePartition/LicensePartition';
import AddLicensePartition from '../modules/licensePartition/AddLicensePartition';
import EditLicensePartition from '../modules/licensePartition/EditLicensePartition';
import StudentPromotionGrid from '../modules/studentPromotion/StudentPromotionGrid'

import ExamType from '../modules/QuestionBank/ExamType'
import AddExamType from '../modules/QuestionBank/AddExamType'
import EditExamType from '../modules/QuestionBank/EditExamType'
import SubjectMappingGrid from '../modules/QuestionBank/SubjectMappingGrid';
import AddSubjectMapping from '../modules/QuestionBank/AddSubjectMapping';
import EditSubjectMapping from '../modules/QuestionBank/EditSubjectMapping';
import GenerateQuestionsForm from '../modules/QuestionBank/GenerateQuestionsForm';
import FeedbackQuestionsGrid from '../modules/QuestionBank/FeedbackQuestionsGrid';
import BluePrintView from '../modules/QuestionBank/BluePrintView';
import FeedbackQuestionsAdd from '../modules/QuestionBank/FeedbackQuestionsAdd';
import FeedbackQuestionsEdit from '../modules/QuestionBank/FeedbackQuestionsEdit';
import AssessmentGrid from '../modules/QuestionBank/AssessmentGrid';
import AssessmentCreate from '../modules/QuestionBank/AssessmentCreate';
import AssessmentEdit from '../modules/QuestionBank/AssessmentEdit'
import ImportQuestion from '../modules/questionset/ImportQuestion'
import ViewStudentReport from '../modules/overallReport/ViewStudentReport'
import ViewReport from '../modules/overallReport/ViewReport'


const Pages: React.FC = () => (
  <Router history={history}>
    <Switch>
      <AuthRoute
        exact
        path={AuthRoutes.dashboard}
        Component={DashboardIndexPage}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.viewschooldetails}
        Component={ViewSchoolDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.viewsubjectdetails}
        Component={ViewSubjectDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      {/* AR Tag Download option */}
      <AuthRoute
        exact
        path={ARTagDownload.ARTag}
        Component={ArTagDownload}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={HelpDownload.HelpDownload}
        Component={HelpguideDownload}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
       <AuthRoute
        exact
        path={DemoDel.Demodelete}
        Component={DemoDelete}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={Question.viewResponses}
        Component={SingleQuizzesView}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />

      {/* classes menu             */}
      <AuthRoute
        exact
        path={commonmenu.viewSchoolTeam}
        Component={ViewSchoolDetailsTeam}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={ClassesMenu.viewClasses}
        Component={classes}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.getClassId}
        Component={classes}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={commonmenu.InstantFeedback}
        Component={InstantFeedback}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.getInstantFeedback}
        Component={InstantFeedback}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={commonmenu.ViewInstantFeedback}
        Component={ViewInstantFeedback}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />

      <AuthRoute
        exact
        path={commonmenu.Attendance}
        Component={Attendance}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.StudentView}
        Component={ViewStudentFull}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.ClassesReport}
        Component={QuizzesReport}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.getQuizzesReport}
        Component={QuizzesReportView}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.Organizations}
        Component={Organizations}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={commonmenu.Quizzes}
        Component={Quizzes}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={commonmenu.getQuizzesId}
        Component={Quizzes}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      {/* School manage menu  */}
      <AuthRoute
        exact
        path={AuthRoutes.addnewschool}
        Component={AddNewSchool}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.editSubject}
        Component={EditSubject}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.teacherAttendance}
        Component={TeacherAttendance}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.addSubject}
        Component={AddNewSubject}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.editschool}
        Component={EditSchool}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={SchoolManage.viewclasses}
        Component={ViewClassesDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={SchoolManage.addclasses}
        Component={AddNewClasses}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={SchoolManage.editclasses}
        Component={EditClasses}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={AuthRoutes.viewSchool}
        Component={SchoolAdminProfile}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />


      {/* User management details */}
      <AuthRoute
        exact
        path={UserManagementUrl.userManageView}
        Component={UserManagement}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={UserManagementUrl.addUserManage}
        Component={AddUserManage}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={UserManagementUrl.editUserManage}
        Component={EditUserManage}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.acadamicAdmin)]}
      />
      {/* student menu list */}
      <AuthRoute
        exact
        path={StudentDetails.studentView}
        Component={ViewStudentDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={StudentDetails.studentAdd}
        Component={AddStudent}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={StudentDetails.studentEdit}
        Component={EditStudent}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={StudentDetails.studentTransfer}
        Component={EditStudent}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={StudentDetails.studnetFullDetails}
        Component={ViewStudentFullDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />

      {/* teacher menu list */}
      <AuthRoute
        exact
        path={TeacherDetails.teacherView}
        Component={TeacherView}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.TeacherEngagement}
        Component={Index}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.acadamicAdmin)]}
      />

      <AuthRoute
        exact
        path={TeacherDetails.teacherFullDetails}
        Component={ViewFullDetailTeacher}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.TeacherProfile}
        Component={ViewFullDetailTeacher}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.addSubject}
        Component={AddSubject}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.teacherAdd}
        Component={AddTeacher}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.teacherEdit}
        Component={EditTeacher}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.AddClassInCharge}
        Component={AddClassInCharge}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.TeacherClassEdit}
        Component={EditClassInCharge}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={TeacherDetails.TeacherSubjectEdit}
        Component={EditTeacherSubject}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />

      {/* Diary List */}
      <AuthRoute
        exact
        path={DiaryList.diaryView}
        Component={ViewDiaryDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      {/* Notice board List */}
      <AuthRoute
        exact
        path={NoticeBoard.noticeView}
        Component={ViewNoticeBoard}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      {/* server side issues */}
      <AuthRoute
        exact
        path={NonAuthRoutes.pageNoFound}
        Component={NotFoundPage}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.schoolAdmin), String(UserRoles.acadamicAdmin), String(UserRoles.teacher)]}
      />
      {/* Question List */}
      <AuthRoute
        exact
        path={Question.ViewQuestion}
        Component={ViewQuestion}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={Question.AddQuestion}
        Component={AddQuestion}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={Question.ViewQuestionlist}
        Component={ViewQuestionDetails}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={Question.studentQuestionView}
        Component={StudentQuestionView}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      {/* This is component upload question */}
      <AuthRoute
        exact
        path={Question.UploadQuestionSet}
        Component={UploadQuestion}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={Question.ImportQuestion}
        Component={ImportQuestion}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />
      <AuthRoute
        exact
        path={Pscyometric.ViewPscyometric}
        Component={ViewPscyometric}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={Pscyometric.AddPscyometric}
        Component={AddPscyometric}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={Pscyometric.EditPscyometric}
        Component={EditPscyometric}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={Pscyometric.StudentListPsychometricReport}
        Component={StudentListPsychometricReport}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={Pscyometric.StudentPsychometricReport}
        Component={StudentPsychometricReport}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />

      {/* QuestionBank List */}
      <AuthRoute
        exact
        path={QuestionBank.BluePrintGrid}
        Component={BluePrintGrid}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.AddBluePrint}
        Component={AddBluePrint}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.EditBluePrint}
        Component={EditBluePrint}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
       <AuthRoute
        exact
        path={QuestionBank.ViewBluePrint}
        Component={BluePrintView}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.ViewGenerateQuestions}
        Component={GenerateQuestions}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />

      <AuthRoute
        exact
        path={QuestionBank.GenerateQuestions}
        Component={GenerateQuestionsForm}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.QuestionBankGrid}
        Component={QuestionBankGrid}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.FeedbackQuestionsGrid}
        Component={FeedbackQuestionsGrid}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
       <AuthRoute
        exact
        path={QuestionBank.AddFeedbackQuestions}
        Component={FeedbackQuestionsAdd}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.EditFeedbackQuestions}
        Component={FeedbackQuestionsEdit}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.ViewQuestionPaper}
        Component={ViewQuestionPaper}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.ExamType}
        Component={ExamType}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.AddExamType}
        Component={AddExamType}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.EditExamType}
        Component={EditExamType}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />

<AuthRoute
        exact
        path={QuestionBank.SubjectMappingGrid}
        Component={SubjectMappingGrid}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.AddSubjectMapping}
        Component={AddSubjectMapping}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />
      <AuthRoute
        exact
        path={QuestionBank.EditSubjectMapping}
        Component={EditSubjectMapping}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />

      {/* StudentPromotion List */}

      <AuthRoute
        exact
        path={StudentPromotion.StudentPromotion}
        Component={StudentPromotionGrid}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin)]}
      />

      {/* FeedBackMenu */}
      <AuthRoute
        exact
        path={FeedBackMenu.viewFeedBack}
        Component={FeedBack}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
      />

      <AuthRoute
        exact
        path={QuestionBank.AssessmentEdit}
        Component={AssessmentEdit}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
        />

      <AuthRoute
          exact
          path={QuestionBank.AssessmentCreate}
          Component={AssessmentCreate}
          LayoutComponent={BaseLayout}
          requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
        />

      <AuthRoute
          exact
          path={QuestionBank.Assessment}
          Component={AssessmentGrid}
          LayoutComponent={BaseLayout}
          requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
        />

      <AuthRoute
       exact
       path={ReportView.Report}
       Component={ViewReport}
       LayoutComponent={BaseLayout}
       requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
       />
       <AuthRoute
       exact
       path={ReportView.StudentReport}
       Component={ViewStudentReport}
       LayoutComponent={BaseLayout}
       requiredRoles={[String(UserRoles.acadamicAdmin), String(UserRoles.schoolAdmin), String(UserRoles.teacher)]}
       />
       <AuthRoute
        exact
        path={LicensePartitionMenu.licensePartition}
        Component={LicensePartition}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={LicensePartitionMenu.addLicensePartition}
        Component={AddLicensePartition}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <AuthRoute
        exact
        path={LicensePartitionMenu.editLicensePartition}
        Component={EditLicensePartition}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />
      <Route
        exact
        path={NonAuthRoutes.login}
        component={UserLogin}
      />
      <Route
        exact
        path={NonAuthRoutes.loginUser}
        component={UserLoginPassword}
      />
      <Route
        exact
        path={NonAuthRoutes.userregister}
        component={UserRegister}
      />
      <Route
        exact path={NonAuthRoutes.userregisterpassword}
        component={UserRegisterPassword}
      />
      <Route
        exact path={NonAuthRoutes.userrotp}
        component={OtpValidation}
      />
      <Route
        exact
        path={NonAuthRoutes.forgotpassword}
        component={ForgotPassword}
      />
      <Route
        exact
        path={NonAuthRoutes.resetpassword}
        component={ResetPassword}
      />
      <Route
        exact
        path={NonAuthRoutes.ForgotOTP}
        component={ForgotOTPCheck}
      />
      <AuthRoute
        exact
        path={NonAuthRoutes.ChangePassword}
        Component={UserProfile}
        LayoutComponent={BaseLayout}
        requiredRoles={[String(UserRoles.acadamicAdmin)]}
      />

      <Route path={NonAuthRoutes.unautheration} render={(props: any) => (<UnAuthorized />)} />

      <Redirect from="/" to="/" />

      <Route component={() => <div>Not Found</div>} />
    </Switch>
  </Router>
)


export default Pages