import React, { Component } from 'react'
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import CommonLoader from '../../components/CommonLoader';
import { QuestionTypes } from '../../store/question/Types';
import { Pscyometric } from '../../router/Roles';
import { fetchQuestionSetPost } from '../../store/question/Actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import { getYearData, stopYearData } from '../../store/profile/Actions'
import { fetchQuestionnaireMappingPost,deleteQuestionnaireMapping } from '../../store/psychometric/Actions';
import {CommonModel} from '../../components/CommonModel';
import {  fetchLicenseBySchoolGet } from '../../store/license/Actions';


export type OwnQuestSetProps = {
  getYear?: any;
  fetchQuestionSetPost: (getPostValue: any) => any;
  fetchQuestionnaireMappingPost: (getPostValue: any) => any;
  getYearData: (postvalue?: any) => any;
  deleteQuestionnaireMapping: (postvalue?: any) => any;
  stopYearData: () => any;
  fetchLicenseBySchoolGet: (getData: any) => any;
  loading: boolean;
  acsOrder: boolean;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?: any;
  license?: any;
  licenseBySchool?: any;
}
export class ViewPscyometric extends Component<OwnQuestSetProps, QuestionTypes> {
  hasMoreClass: any;
  constructor(props: any) {
    super(props);
    this.state = {
      acsOrder: true,
      academicYear: null,
      search: '',
      hasMore: true,
      page: 1,
      page_no: 1,
      SortOrderData: '',
      OrderNameData: '',
      recordPage: 1,
      sort_by: 'desc',
      sort_class_name: 'fa fa-sort-amount-asc',
      sort_title_name: 'Descending order',
      showDelete: false,
      deleteId:'',
      licenseBySchool: {}
    }
  }
  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getUpdateYear()
  }
  componentWillUpdate(nextProps: any, nextState: any) {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.getUpdateYear(this.props.getYear)
    }
  }
  componentDidUpdate() {
    const { stopYear } = this.props;
    if (stopYear === true) {
      this.props.stopYearData()
    }
  }
  getUpdateYear(getYear?: any) {
    if (getYear && getYear !== undefined && getYear !== null) {
      this.setState({ academicYear: getYear })
      this.getPsychometricData(getYear)
    } else {
      this.setState({ academicYear: this.props.getYear })
      this.getPsychometricData(this.props.getYear)
    }
  }
  getPsychometricData(getYear?: any) {
    this.getPsychometricDetails(getYear)
  }
  getPsychometricDetails(getYear?: any) {
    const getPostStudent = {
      page_no: 1,
      academic_year: getYear,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData
    }

    const getLicenseBySchool = {
      academic_year: getYear,
      module_name:'psychometric_module'
    }
    this.props.fetchLicenseBySchoolGet(getLicenseBySchool).then((res: any) => {
      const { licenseBySchool } = this.props
      const psychometricLicense = licenseBySchool;
      this.setState({ licenseBySchool: psychometricLicense });
    })


    this.props.fetchQuestionnaireMappingPost(getPostStudent).then((res: any) => {
      this.setState({ hasMore: true, page: 1 })
    });
  }
  
  showFilterData(getValues: any) {
    const { academicYear, page } = this.state;
    if (getValues.sort_by === 'desc') {
      this.setState({
        acsOrder: false,
        search: '',
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by
      })
      const postValue = {
        page_no: page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'asc',
        sort_class_name: 'fa fa-sort-amount-up',
        sort_title_name: 'Ascending order'
      })
      this.props.fetchQuestionnaireMappingPost(postValue).then((res: any) => {
      });
    } else {
      this.setState({
        acsOrder: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        search: ''
      })
      const postValue = {
        page_no: page,
        academic_year: academicYear,
        search: this.state.search,
        sort_by: getValues.sort_by,
        order_by: getValues.order_by
      }
      this.setState({
        hasMore: true,
        SortOrderData: getValues.sort_by,
        OrderNameData: getValues.order_by,
        page: 1,
        sort_by: 'desc',
        sort_class_name: 'fa fa-sort-amount-asc',
        sort_title_name: 'Descending order'
      })
      this.props.fetchQuestionnaireMappingPost(postValue).then((res: any) => {
      });
    }
  }
  public fetchMoreStudentData = () => {
    const { page, academicYear } = this.state;

    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: page + 1,
          academic_year: academicYear,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          search: this.state.search
        }
        this.props.fetchQuestionnaireMappingPost(postValue).then((res: any) => {
        });
        this.setState({
          page: page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }
  private renderPsychometricDetails(records: any) {
    const { sort_by, sort_class_name, sort_title_name } = this.state;
    const { loading } = this.props;
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    return (
      <div>
        <InfiniteScroll
          dataLength={records.length}
          next={this.fetchMoreStudentData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingQuestionFull}>Loading...</h4>}
        >
          <table className="table table-striped custom-table table-hover">
            <thead>
              <tr>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'questionnaire_name' })}
                    title={sort_title_name} className="headerBold">Questionnaire Name&nbsp;
                    <i className={sort_class_name} aria-hidden="true"></i></button>
                </th>
                <th>Created At</th>
                <th>Edit</th>
                <th>View Student</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ?
                records.map((items: any) => (
                  <tr>
                    <td >{items['questionnaire_name'] || '-'}</td>
                    <td >{items['format_date'] || '-'}</td>
                    
                    <td>
                    {items.unique_id && items.is_editable ?
                      <Link to={`/psychometric/${items.unique_id}`}>
                        <button className="btn btn-primary btn-xs"
                          title="Edit"><i className="fa fa-pencil" aria-hidden="true" ></i></button></Link> :
                      <button className="btn btn-disable btn-xs"
                        title="Edit"><i className="fa fa-pencil" aria-hidden="true" ></i></button>}
                    </td>
                    <td>
                      {items.unique_id ?
                        <Link to={`/psychometric/studentlist/${items.unique_id}`}>
                          <button className="btn btn-primary btn-xs"
                            title="View"><i className="fa fa-eye" aria-hidden="true"></i></button></Link> :
                        <button className="btn btn-disable btn-xs"
                          title="View"><i className="fa fa-eye" aria-hidden="true"></i></button>
                          }
                    </td>
                    <td>
                      {items.unique_id ?
                        
                          <button className="btn btn-primary btn-xs"
                            title="Delete"><i className="fa fa-trash" aria-hidden="true"
                            onClick={() => this.showDeletePopup(items.unique_id)}></i></button> :
                        <button className="btn btn-disable btn-xs"
                          title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></button>
                          }
                    </td>
                  </tr>
                ))
                : <CommonLoader />}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    )
  }

  handleInputQuestionSet = (e: any) => {
    const { value } = e.target;
    const { academicYear } = this.state;
    const getSearchValue = value || '';

      const postSearchValue = {
        page_no: 1,
        search: getSearchValue,
        academic_year: academicYear,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData
      }
      this.props.fetchQuestionnaireMappingPost(postSearchValue).then((res: any) => {
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      });
      this.hasMoreClass = true;
    
  }

  refreshPsychometricTable = () => {
    const { academicYear } = this.state;
    this.getPsychometricDetails(academicYear)
  }

  public handleDelete = () => {
    this.setState({ showDelete: false, page: 1, hasMore: true });
    this.props.deleteQuestionnaireMapping(this.state.deleteId).then((res:any)=>{
      const {academicYear} = this.state;
      this.getPsychometricDetails(academicYear)
    })

  }
  public hideQuestionSetDelete = () => {
    this.setState({ showDelete: false });
  }

  public showDeletePopup = (getData: any) => {
   
    if(getData){
      this.setState({ showDelete: true, deleteId: getData});
    }
  }

  render() {
    const { loading } = this.props;
    const { showDelete, licenseBySchool } = this.state;
    const loadingQuestionFull = { display: loading ? "block" : "none" };
    const postValue:any = {
      title:'Please Confirm',
      action_status:'delete',
      action_name:'Questionare'
    }
    return (
      <div>
      <CommonModel
       onState={showDelete}
       currentState={postValue}
       onConfirm={this.handleDelete}
       onCancel={this.hideQuestionSetDelete}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['Psychometric Test']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['Psychometric Test']} />
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="mr-4 mt-2">
                    <h4 className="pull-right">Total: {this.props.total}</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">

                  <div className="col-md-12 col-lg-12">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="overview-panel purple
                                            text-center mt-2 mb-1">
                            <div className="value white">
                              <p className="mb-1 mt-1"> Total License</p>
                              <h5 className="mt-1 color-pink">
                                {licenseBySchool && licenseBySchool.total_license}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="overview-panel purple
                                            text-center mt-2 mb-1">
                            <div className="value white">
                              <p className="mb-1 mt-1"> Remaining License</p>
                              <h5 className="mt-1 color-pink">
                                {licenseBySchool && licenseBySchool.remaining_license}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-head">
                      <header>
                        <Link to={Pscyometric.AddPscyometric}>
                          <button className="btn btn-pink">Add Psychometric Test</button>
                        </Link>
                      </header>
                      <div className="tools">
                        <div className="d-flex">
                          <div>
                            <input
                              placeholder="Search"
                              name="search"
                              className="form-control"
                              onChange={this.handleInputQuestionSet}
                            />
                          </div>
                          <div className="ml-2 mt-2">
                            <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshPsychometricTable}></span>
                            <span className="t-collapse btn-color fa fa-chevron-up pointer" ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">
                        <div className="table-responsive">
                          {this.renderPsychometricDetails(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={loadingQuestionFull}><SpinnerLoader /></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.psychometric.loading,
    getYear: state.profile.getYear,
    stopYear: state.profile.yearStatusFails,
    total: state.psychometric.total,
    per_page: state.psychometric.per_page,
    records: state.psychometric.records,
    page: state.psychometric.page,
    totalPage: state.psychometric.totalPage,
    license: state.license.license,
    licenseBySchool: state.license.licenseBySchool
  }
}

const mapDispatchToProps = {
  fetchQuestionSetPost,
  fetchQuestionnaireMappingPost,
  stopYearData,
  getYearData,
  deleteQuestionnaireMapping,
  fetchLicenseBySchoolGet
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPscyometric)
