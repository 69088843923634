export interface AUTHAPITYPE {
    CHECKUSER:string,
    LOGIN:string,
    FORGOTPASSWORD:string,
    FORGOTPASSWORDOTPVERIFY:string,
    RESETPASSWORD:string,
    REGISTERUSER:string
    LOGOUT:string,
    PROFILE:string,
    OTPCHECK:string,
    GENERATOTP:string,
    CHECKTOKENVALIDATION:string,
    GetTeacher:any,
    GetActiveTeacher:any
}

export const AUTHAPI: AUTHAPITYPE = {
    CHECKUSER:"/auth/check-user/",
    LOGIN:"/auth/login/?app=web",
    FORGOTPASSWORD:"/auth/forgot-password/",
    FORGOTPASSWORDOTPVERIFY:"/auth/forgot-password-otp-verify/",
    RESETPASSWORD:"/auth/reset-password/",
    REGISTERUSER:"/auth/register/",
    LOGOUT:'/auth/logout/',
    PROFILE:'/auth/profile/',
    OTPCHECK:"/auth/verify-otp/",
    GENERATOTP:"/auth/generate-otp/",
    CHECKTOKENVALIDATION:"/auth/token-validate/",
    GetTeacher:'/auth/get-all-teachers/',
    GetActiveTeacher:'/auth/get-all-active-teachers/'
};

export interface SCHOOLMANGETYPE {
    SCHOOLMANAGEVIEW:string,
    SCHOOLMANAGEDELETE:string,
    CLASSES:string,
    GRADELIST:string,
    GRADESTANDARD:string,
    topicClassAPI:string,
    commonListItem:string
}
export const SCHOOLMANAGE: SCHOOLMANGETYPE ={
    SCHOOLMANAGEVIEW:'/api/school/',
    SCHOOLMANAGEDELETE:'/api/users/deactivate/',
    CLASSES:'/api/classes/',
    GRADELIST:'/api/lovs/grade/',
    GRADESTANDARD:'/api/lovs/standard',
    topicClassAPI:'/api/dashboard/topic-analysis-dropdown',
    commonListItem:'/api/lovs/'
}

export interface SUBJECTMANGETYPE {
    SUBJECTMANAGEVIEW:string,
    SUBJECTMANAGEDELETE:string,
    SubjectList:string,
    teacherSubjectMap?:any,
    schoolCheckList?:any,
    getMappedSubjectList?:any
}
export const SUBJECTMANAGE: SUBJECTMANGETYPE ={
    SUBJECTMANAGEVIEW:'/api/subject/',
    SUBJECTMANAGEDELETE:'/api/users/deactivate/',
    SubjectList:'/api/subject/year-based-dropdown/',
    teacherSubjectMap:'/api/subject/get-school-subjects/',
    schoolCheckList:'/api/subject/get-school-list-details/',
    getMappedSubjectList:'/api/subject/get-school-subjects/'
}

export interface CATEGORYMANAGETYPE {
    CATEGORYMANAGEVIEW:string,
    YearList:string,
    durationChart:string
}
export const CATEGORYMANAGE: CATEGORYMANAGETYPE = {
    CATEGORYMANAGEVIEW:'/api/lovs/subject_category/',
   // YearList:'/api/lovs/academic_year/',
    YearList:'/api/dashboard/year',
    durationChart:'/api/lovs/duration/'
}

export interface subjectRequestData {
    "url": "/api/subject/",
}

export const USERNAMENAGE = {
    USERMANAGE:'/auth/user/',
    GETUSER:'/auth/get-users/',
    GETSCHOOLCATEGORY:'/api/school/school-list',
    changePasswordAPI:'/auth/change-password/'
}

export const STUDENTS = {
    STUDENTS:'/api/students/',
    CHECKPARENT:'/auth/validate-parent-mobile/',
    STUDNETSIMAGEUPDATE:'/api/students/upload-profile/',
    studentProfile:'/api/dashboard/student-basic-report',
    studentProfileTopic:'/api/dashboard/student-profile-by-topic',
    studentProfileSubject:'/api/dashboard/student-profile-by-subject',
    studentProfileLine:'/api/students/performance-web-details',
    getStudentNameList:'/api/students/get-students-by-class/',
    onUpdateAttendance:'/api/attendance/student-attendance-audit',
    studentPromotion:'/api/students/student-promotion',
    studentByAcademicYear: "/api/classes/get-students-by-academic_year",
    studentNewClass: "/api/classes/get-next-classes"
}

export const IMAGEUPLOAD = {
    STUDENTIMAGEUPLOAD:'/api/students/uploadprofile/',
    TeacherImageUpload:'/api/teacher/upload-profile/'
}

export const DIARYAPI = {
    DIARY:'/api/diary/',
    GETALLSTUDENTLIST:'/api/classes/get-all-standards/',
    GETSTUDENTLIST:'/api/classes/student-list/',
    DIARYAPIIMAGEUPDATE:'/api/diary/image-upload/',
    GETALLSTUDENTSEARCHLIST: '/api/students/get-all-students/',
    DiaryDropDown:'/api/lovs/diary_type/'
}

export const Teacher = {
    TeacherPost:'/api/teacher/subject-mapping/',
    TeacherId:'/api/teacher/get-class-count/',
    TeacherClass:'api/teacher/class-incharge/',
    TeacherSubjectClassEdit:'/api/teacher/get-class-count/',
    ClassCount:'/api/teacher/get-class-count/',
    TeacherAttendanceReport:'/api/teacher/attendance-report/',
    deleteSubjectMap:'/api/teacher/deactivate-subject/',
    deleteClassIncharge:'/api/teacher/deactivate-class-incharge/',
    getActiveClassTeacher:'/api/teacher/get-active-school-class-teacher/'
}

export const QuestionSet = {
    Question:'/api/questionset/',
    teacherQuestion:'/api/teacher/subject-dropdown/',
    // downloadFile:'/api/questionset/questionset-format-download/',
    // questionsetCsv:'/api/questionset/questionset-csv-upload/'
    downloadFile:'/api/questionset/questionset-xlsx-download/',
    questionsetCsv:'/api/questionset/questionset-xlsx-upload/',
    GenerateRandomQuizQuestion: '/api/questionbank-module/generate-quiz-random-question/',
}

export const NoticesBoard = {
    noticeboard:'/api/notice_board/',
    noticeboardImage:'/api/notice_board/noticeboard-image-upload/'
}
export const DemoDelete = {
    QuizDelete : '/api/psychometric-module/delete-quiz-demo/',
    TeacherAttendanceDelete : '/api/psychometric-module/delete-teacher-attendance-demo/',
    StudentAttendanceDelete :'/api/psychometric-module/delete-student-attendance-demo/',
    PsychometricDelete : '/api/psychometric-module/delete-psycho-demo/',
}
export const ListItem = {
    myClassList:'/api/classes/get-my-grades/',
    mySectionList:'/api/classes/get-my-sections/',
    getSubjectBased :'/api/subject/get-grade-subjects/',
    getSubjectBasedTeacher :'/api/subject/get-class-subjects/'
}
export const DashboardAPI = {
    dashboard:'/api/dashboard/dashboard-count',
    TeacherEngagement:'/api/dashboard/teacher-report/',
    StandardAnalysis:'/api/dashboard/standard-analysis',
    SubjectAnalysis:'/api/dashboard/subject-analysis',
    InstantFeedBack:'/api/instantfeedback/instant-feedback-list',
    attendanceUse:'/api/dashboard/get-attendance',
    feedbacksUse:'/api/dashboard/get-feedback',
    quizesUse:'/api/dashboard/get-quizes',
    topicAnalysis:'/api/dashboard/topic-analysis',
    subjectPerformance:'/api/dashboard/subject-performance',
    concernPoint:'/api/dashboard/point-of-concern',
    classReports:'/api/classes/get-class-reports/',
    quizReport:'/api/quiz/quiz-report',
    attendeanceReport:'/api/attendance/attendance-report',
    teacherPerformance:'/api/teacher/get-performance-count',
    teacherSubjectPerformance:'/api/teacher/subject-performance',
    teacherOverallPerformance:'/api/teacher/overall-performance',
    recentFeedbackList:'/api/instantfeedback/recent-instant-feedback-list',
    recentQuizReport:'/api/quiz/recent-quiz-report',
    topicAnalysisReport:'/api/dashboard/topic-analysis-report',
    ClassrePort:'/api/dashboard/class-report',
    todaysAttendance:'/api/attendance/todays-attendance',
    gradeComparisonReport:'/api/dashboard/grade-comparison-report',
    classRecentFeedback:'/api/dashboard/class-recent-feedback',
    classRecentQuiz:'/api/dashboard/class-recent-quiz',
    studentRecentQuiz:'/api/quiz/student-recent-quiz',
    diaryReply:'/api/diary/diary-reply/',
    gradeSubjectList:'/api/subject/dropdown/',
    downloadUserGuide: 'api/dashboard/help-guide-download'
}
// This instant feedback Api link
export const InstantFeedBackAPI = {
    feedbackBarChart:'/api/instantfeedback/instant-feedback-bar-chart',
    feedbackPieChart:'/api/instantfeedback/instant-feedback-pie-chart',
    feedbackStudent:'/api/instantfeedback/instant-feedback-student-details',
    feedbackSubject:'/api/instantfeedback/instant-feedback-subject-details'
}

export const QuizzesReportAPI = {
    quizQuestionList:'/api/quiz/quiz-question-list',
    quizCalculation:'/api/quiz/quiz-calculation',
    quizStudentDetails:'/api/quiz/quiz-student-detail',
    quizBarChart:'/api/quiz/quiz-barchart',
    questionReportView:'/api/quiz/quiz-question-view',
    singleQuizStudent:'/api/quiz/quiz-single-student',
    singleQuizQuestionList:'/api/quiz/quiz-question-list-single-student'
}
// /api/quiz/quiz_question_list_single_student?quiz_id=11&card_id=1

export const leastAttendees = {
    getLeastAttendees:'/api/dashboard/least-attendees'
}

export const onGetValue = {
    getClassList:'/api/classes/classlist',
    ArTagDownload:'/api/students/artag-file/'
}

export const SubjectMapping = {
    GetSubjectList:'/api/subject/get-details/',
    GetSubject:'/api/subject/'
}

export const Psychometric = {
    GetPsychometricAgeDropdown:'/api/psychometric-module/age-dropdown/',
    GetAgeQuestionnaireDetails:'/api/psychometric-module/age-questionnaire-details/',
    CreateQuestionnaireMapping:'/api/psychometric-module/create-questionnaire-mapping/',
    ViewQuestionnaireMapping:'/api/psychometric-module/view-questionnaire-mapping/',
    GetQuestionnaireMappingById:'/api/psychometric-module/get-questionnaire-mappings/',
    GetStudentList:'/api/psychometric-module/student-list/',
    GetStudentPsychometricReport:'/api/psychometric-module/report/',
    GetQuestionnaireMappingStudents: '/api/psychometric-module/get-questionnaire-mapping-students/',
    
}

export const License = {
    // GetLicenseValidate:'/api/license-module/license-validate/',
    GetLicenseValidate:'/api/license-module/check-license/',
    GetMenuList:'/api/license-module/menu-list/',
    GetSuperuserLicenseDetails:'/api/license-module/get-superuser-license-details/',
    //LicensePartition:'/api/license-module/license-partition/',
    LicensePartition:'/api/license-module/license-partition-view/',
    GetLicenseSchoolList:'/api/license-module/get-license-school-list/',
    //GetLicenseBySchool:'/api/license-module/get-license-partition-details/'
    GetLicenseBySchool:'/api/license-module/license-validate/'
}

export const QuestionBank = {
    GetExamType: '/api/questionbank-module/exam-type/',
    BluePrint: '/api/questionbank-module/blueprint/',
    GetBluePrintGradeDropdown: '/api/questionbank-module/blueprint-grade-dropdown/',
    GetBluePrintSubjectDropdown: '/api/questionbank-module/blueprint-subject-dropdown/',
    GetBluePrintExamTypeDropdown: '/api/questionbank-module/exam-type-dropdown/',
    GetBluePrintLessonDropdown: '/api/questionbank-module/blueprint-lesson-dropdown/',
    GetBluePrintDifficultyLevelDropdown: '/api/questionbank-module/blueprint-level-dropdown/',
    GetBluePrintQuestionTypeDropdown: '/api/questionbank-module/question-type-mark-dropdown/',
    InternalGradleDropdown: '/api/questionbank-module/qb-internal-grade-dropdown/',
    InternalSubjectDropdown: '/api/questionbank-module/qb-internal-subject-dropdown/',
    ExternalSubjectDropdown: '/api/questionbank-module/qb-external-subject-dropdown/',
    LanguageDropdown: '/api/questionbank-module/language-dropdown/',
    SubjectMapping: '/api/questionbank-module/subject-mapping/',
    GenerateQuestions: '/api/questionbank-module/generate-questions/',
    ReloadQuestions: '/api/questionbank-module/reload-questions/',
    GetBluePrintDropdown: '/api/questionbank-module/blueprint-dropdown/',
    GetQuestionPaperDropdown: '/api/questionbank-module/questionpaper-dropdown/',
    CreateQuestionpaper: '/api/questionbank-module/create-question-paper/',
    DownloadQuestionpaper: '/api/questionbank-module/download-question-paper/',
    FeedbackQuestions: '/api/questionbank-module/feedback-questions/',
    DownloadOMRSheet: '/api/questionbank-module/download-omr-sheet/',
}

export const StudentPromotion = {
    StudentPromotion: "/api/student/student-promotion"
}

export const Assessment = {
    AssesmentView:'/api/questionbank-module/create-assessment/',
    AssessmentCreate: '/api/questionbank-module/create-assessment/',
    AssessmentEdit:'/api/questionbank-module/create-assessment/',
    AssessmentDelete:'/api/questionbank-module/create-assessment/',
    AssessmentQuestionFetch:'/api/questionbank-module/view-questions/',
    GetAssessmentStudentList:'/api/questionbank-module/view-assessment-studentlist/',
    GetAssessment: '/api/questionbank-module/create-assessment/',
}

export const Reports = {
    TeacherDropdown: '/api/reports/teacher-dropdown/',
    SubjectDropdown: '/api/reports/subject-dropdown/',
    SubjectAnalysisReport: '/api/reports/subject-analysis-report/',
    TeacherEngagementReport: '/api/reports/teacher-engagement-report/',
    TeacherPerformanceReport: '/api/reports/teacher-performance-report/',
    TeacherAttendanceReport:'/api/reports/teacher-attendance/',
    ClassAttendanceReport:'/api/reports/class-attendance/',
    SubjectPerformanceReport:'/api/reports/subject-performance-report/',
    SubjectAttendivenessReport:'/api/reports/attendiveness-report/',
    TeacherOverallReport:'/api/reports/teacher-overall-report/',
}